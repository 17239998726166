import { openInNewTab } from "../../helper";
import { Popout } from "react-portal-popout";

const CouncilRules = (props: {
  name: any;
  title: any;
  councilRules: any[];
  onClose: () => void;
}) => {
  const { name, title, councilRules, onClose } = props;
  let maxDate = new Date(0);
  // Iterate over each category in the councilRules
  for (const category in councilRules) {
    if (Object.hasOwnProperty.call(councilRules, category)) {
      const categoryObjects = councilRules[category];
      // Iterate over each object in the category
      categoryObjects.forEach((obj: any) => {
        // Extract the last_modified_date
        const currentDate = new Date(obj.last_modified_date);
        // Update maxDate if currentDate is later
        if (currentDate > maxDate) {
          maxDate = currentDate;
        }
      });
    }
  }
  // Format the maxDate as a string in the desired format (e.g., YYYY-MM-DD)
  const latestModifiedDate = maxDate.toISOString().split("T")[0];
  return (
    <Popout
      name={name}
      title={title}
      onClose={onClose}
      options={{ scrollbars: false, width: 1000, height: 500 }}
    >
      <div style={{ padding: "15px", textAlign: "center" }}>
        <h1>Council Rules for {name}</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          {Object.keys(councilRules).map((device: any, index: number) => {
            return (
              <div key={index}>
                <span style={{ fontSize: "18px", marginTop: "10px" }}>
                  {device}:{" "}
                </span>
                {Object.values(councilRules[device]).map((rule: any) => {
                  return (
                    <li
                      key={rule.id}
                      style={{ fontSize: "15px", lineHeight: 2 }}
                    >
                      {rule.council_rule}{" "}
                      {rule.link !== null && rule.link !== "null" && (
                        <a
                          href="#"
                          onClick={(e: any) => {
                            e.preventDefault();
                            openInNewTab(rule.link);
                          }}
                        >
                          Click to check
                        </a>
                      )}
                    </li>
                  );
                })}
              </div>
            );
          })}
        </div>
        <h3 style={{ position: "absolute", margin: "20px 0", right: "50px" }}>
          Last modified at: {latestModifiedDate}
        </h3>
      </div>
    </Popout>
  );
};

export default CouncilRules;
