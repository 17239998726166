export const buttonPriceList = {
    jco: [
        // { title: "run model", price: "1.69" },
        { title: 'Download Report', price: '69.69' },
        { title: 'Save MSF file', price: '6.90' },
        { title: 'Save MLB file', price: '4.20' },
        { title: 'Run Model Button', price: '0.069' },
        // { title: "upload company icon", price: "1.69" },
    ],
    calculator: [
        { title: 'MLB File', price: '4.2' },
        { title: 'Lite WSUD Report', price: '0.69' },
        { title: 'WSUD Report', price: '24.69' },
    ],
    osd: [
        { title: 'OSD Report', price: '24.69' },
        { title: 'Calculate OSD Button', price: '0.069' },
    ],
};
