import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { handleSubscriptionPaymentSuccess } from "../../dbquery/dbquery";
import { useSelector } from "react-redux";
import axios from "axios";
import ConfirmationPage from "./Confirmationpage";

const PaymentSuccessPage: React.FC = () => {
  const location = useLocation();
  const idToken = useSelector((state: any) => state.accountToken.idToken);
  const [paymentStatus, setPaymentStatus] = useState<{
    status: string;
    product: string;
    customer_email: string;
    amount_total: number;
    amount_subtotal: number;
    interval: string;
    interval_count: number;
    current_period_end: number;
    subscription_id: string;
  }>({
    status: "",
    product: "",
    customer_email: "",
    amount_total: 0,
    amount_subtotal: 0,
    interval: "",
    interval_count: 0,
    current_period_end: 0,
    subscription_id: "",
  });
  const reformatToolName = (toolName: string) => {
    if (toolName.toLowerCase() === "jco subscription") return "jco";
    if (toolName.toLowerCase() === "su calculator") return "su";
    if (toolName.toLowerCase() === "osd subscription") return "osd";
    else return toolName;
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      fetch(
        `https://toglm12jl3.execute-api.ap-southeast-2.amazonaws.com/test`,
        {
          method: "POST",
          body: JSON.stringify({ sessionId: sessionId }),
        }
      )
        .then((res) => res.json())
        .then((data: any) => {
          // console.log("data", data);
          setPaymentStatus(data)
        });
    }
  }, [location.search]);

  useEffect(() => {
    if (paymentStatus.status === "complete") {
      const days = paymentStatus.interval === "month" ? 30 : 7;
      const duration = days * paymentStatus.interval_count;
      let toolName = reformatToolName(paymentStatus.product);
      const handleSuccess = async () => {
        const success = await handleSubscriptionPaymentSuccess(
          idToken["cognito:username"],
          paymentStatus.subscription_id,
          toolName,
          paymentStatus.amount_subtotal / 100,
          paymentStatus.amount_total / 100,
          duration
        );

        if (success) {
          const formData = new FormData();
          formData.append("endpoint", "sendUserNewPaymentNotification");
          formData.append(
            "params",
            JSON.stringify({
              product: toolName,
              amount_total: paymentStatus.amount_total / 100,
              amount_subtotal: paymentStatus.amount_subtotal / 100,
              customer_email: paymentStatus.customer_email,
              name: idToken.name,
            })
          );
          axios
            .post(
              "https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/emailer",
              formData
            )
            .catch((error) => console.error("An error occurred:", error));
        }
      };

      handleSuccess();
    }
  }, [paymentStatus.status, idToken]);

  return <ConfirmationPage />;
};

export default PaymentSuccessPage;
