import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const LoadingSpinner = () => {
    return (
        <Player
            autoplay
            loop
            src="https://assets9.lottiefiles.com/private_files/lf30_ttbbgf8m.json"
            style={{ height: '350px', width: '350px' }}
        />
    );
};

export default LoadingSpinner;
