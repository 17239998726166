import React, { useEffect, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getMethodsAPI } from '../../dbquery/dbquery';
import '../../asset/css/component.css';

const ViewCreditUsage = () => {
    const [creditUsageHistory, setCreditUsageHistory] = useState([]);
    const user_id = useSelector((state: any) => state.accountToken.idToken['cognito:username']);

    useEffect(() => {
        const getCreditUsageHistory = async () => {
            await getMethodsAPI({
                endpoint: 'getUserUsageHistory',
                user_id,
            }).then((response) => setCreditUsageHistory(response.data));
        };
        getCreditUsageHistory();
    }, []);

    return (
        <Container sx={{ mt: '30px' }}>
            <Paper elevation={4} sx={{ mt: '30px', mb: '10px', padding: '5px' }}>
                <Typography variant="h5" sx={{ mb: 2, mt: 1, ml: 2 }}>
                    Credit Usage History
                </Typography>
                <Divider />
                <div className="usageContainer">
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <div className="creditUsageRow">Time</div>
                            <div className="creditUsageRow">Service</div>
                            <div className="creditUsageRow">Amount</div>
                        </div>
                        {creditUsageHistory.map((history: any) => (
                            <div style={{ display: 'flex' }}>
                                <div className="creditUsageRow">
                                    {history?.timestamp?.split('T').join(' ')}
                                </div>
                                <div className="creditUsageRow">{history.service_name}</div>
                                <div className="creditUsageRow">
                                    {history.used_credit_amount} SD
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Paper>
        </Container>
    );
};

export default ViewCreditUsage;
