import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Container,
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import {
    getJcoModelsForUser,
    getJcoReportsForUser,
    getOsdModelsForUser,
    getSuModelsForUser,
    getSuReportsForUser,
} from '../../dbquery/dbquery';
import { useSelector } from 'react-redux';
import ProjectCard from './ProjectCard';
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion/AccordionStyle';
import { handleOpenProject } from '../../helper';

const ProjectList = () => {
    const [orderBy, setOrderBy] = useState('');
    const [filter, setFilter] = useState('');
    const [projects, setProjects] = useState<any[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<any[]>([]);
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const reshapeArray = (models: any[], reports: any[]) => {
        const mergedObjs: any[] = [];
        // Merge models into mergedObjs
        models.forEach((model) => {
            const existing = mergedObjs.find((item) => item.addressString === model.addressString);
            if (existing) {
                existing.models.push(model);
                if (model.date > existing.date) {
                    existing.date = model.date;
                }
            } else {
                mergedObjs.push({
                    addressString: model.addressString,
                    date: model.date,
                    models: [model],
                    reports: [],
                });
            }
        });
        // Merge reports into mergedObjs
        reports.forEach((report) => {
            const existing = mergedObjs.find((item) => item.addressString === report.addressString);
            if (existing) {
                existing.reports.push(report);
            }
            // else {
            //     mergedObjs.push({
            //         addressString: report.addressString,
            //         date: report.date,
            //         models: [],
            //         reports: [report],
            //     });
            // }
        });
        mergedObjs.forEach((obj) => {
            obj.reports.sort(
                (a: { date: string | number | Date }, b: { date: string | number | Date }) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime(),
            );
        });
        return mergedObjs;
    };

    const reformProjects = (projects: any) => {
        return projects.map((project: any) => {
            project.date = new Date(project.time).toLocaleString('en-US', {
                timeZone: 'Australia/Melbourne',
            });
            const newObj = {
                model_id: project.model_id,
                date: project.date,
                name: project.project_name,
                addressString: project.address,
                fromReport: project['has_report'],
                saveName: project['save_name'],
            };
            return newObj;
        });
    };
    const getAllProjects = async () => {
        try {
            const jcoModels = await getJcoModelsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });
            const suModels = await getSuModelsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });
            const osdModels = await getOsdModelsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });
            const jcoReports = await getJcoReportsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });

            const suReports = await getSuReportsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });
            const osdReports = await getOsdModelsForUser(idToken.email).then((res) => {
                const reformedProjects = reformProjects(res);
                return reformedProjects;
            });
            const su = reshapeArray(suModels, suReports);
            const jco = reshapeArray(jcoModels, jcoReports);
            const osd = reshapeArray(osdModels, osdReports);
            const mergedProjects = mergeArrays(jco, su, osd);

            setProjects(mergedProjects);
            setFilteredProjects(mergedProjects);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const mergeArrays = (jco: any, su: any, osd: any) => {
        var mergedArray: any = [];
        const mergedProjectAddresses: string[] = [];

        jco.forEach(function (item: any) {
            var correspondingItem = su.find(function (element: any) {
                return element.addressString === item.addressString;
            });
            var osdCorrespondingItem = osd.find(function (element: any) {
                return element.addressString === item.addressString;
            });

            if (correspondingItem && osdCorrespondingItem) {
                mergedProjectAddresses.push(correspondingItem.addressString);
                var jcoLatestDate = new Date(
                    Math.max(
                        ...item.models
                            .map((model: any) => new Date(model.date))
                            .concat(item.reports.map((report: any) => new Date(report.date))),
                    ),
                );

                var suLatestDate = new Date(
                    Math.max(
                        ...correspondingItem.models
                            .map((model: any) => new Date(model.date))
                            .concat(
                                correspondingItem.reports.map(
                                    (report: any) => new Date(report.date),
                                ),
                            ),
                    ),
                );

                var osdLatestDate = new Date(
                    Math.max(
                        ...osdCorrespondingItem.models
                            .map((model: any) => new Date(model.date))
                            .concat(
                                osdCorrespondingItem.reports.map(
                                    (report: any) => new Date(report.date),
                                ),
                            ),
                    ),
                );

                var latestDate = Math.max(
                    jcoLatestDate.getTime(),
                    Math.max(suLatestDate.getTime(), osdLatestDate.getTime()),
                );
                var mergedObj = {
                    addressString: item.addressString,
                    date: new Date(latestDate).toLocaleString('en-US', {
                        timeZone: 'Australia/Melbourne',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                    }),
                    jco: item,
                    su: correspondingItem,
                    osd: osdCorrespondingItem,
                };
                mergedArray.push(mergedObj);
            } else if (correspondingItem) {
                mergedProjectAddresses.push(correspondingItem.addressString);
                var jcoLatestDate = new Date(
                    Math.max(
                        ...item.models
                            .map((model: any) => new Date(model.date))
                            .concat(item.reports.map((report: any) => new Date(report.date))),
                    ),
                );

                var suLatestDate = new Date(
                    Math.max(
                        ...correspondingItem.models
                            .map((model: any) => new Date(model.date))
                            .concat(
                                correspondingItem.reports.map(
                                    (report: any) => new Date(report.date),
                                ),
                            ),
                    ),
                );

                var latestDate = Math.max(jcoLatestDate.getTime(), suLatestDate.getTime());
                var mergedObjSU = {
                    addressString: item.addressString,
                    date: new Date(latestDate).toLocaleString('en-US', {
                        timeZone: 'Australia/Melbourne',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                    }),
                    jco: item,
                    su: correspondingItem,
                };
                mergedArray.push(mergedObjSU);
            } else if (osdCorrespondingItem) {
                mergedProjectAddresses.push(osdCorrespondingItem.addressString);
                var jcoLatestDate = new Date(
                    Math.max(
                        ...item.models
                            .map((model: any) => new Date(model.date))
                            .concat(item.reports.map((report: any) => new Date(report.date))),
                    ),
                );

                var osdLatestDate = new Date(
                    Math.max(
                        ...osdCorrespondingItem.models
                            .map((model: any) => new Date(model.date))
                            .concat(
                                osdCorrespondingItem.reports.map(
                                    (report: any) => new Date(report.date),
                                ),
                            ),
                    ),
                );

                var latestDate = Math.max(jcoLatestDate.getTime(), osdLatestDate.getTime());
                var mergedObj2 = {
                    addressString: item.addressString,
                    date: new Date(latestDate).toLocaleString('en-US', {
                        timeZone: 'Australia/Melbourne',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                    }),
                    jco: item,
                    osd: osdCorrespondingItem,
                };
                mergedArray.push(mergedObj2);
            }
        });

        su.forEach(function (item: any) {
            if (mergedProjectAddresses.includes(item.addressString)) return;
            var osdCorrespondingItem = osd.find(function (element: any) {
                return element.addressString === item.addressString;
            });

            if (osdCorrespondingItem) {
                mergedProjectAddresses.push(osdCorrespondingItem.addressString);
                var jcoLatestDate = new Date(
                    Math.max(
                        ...item.models
                            .map((model: any) => new Date(model.date))
                            .concat(item.reports.map((report: any) => new Date(report.date))),
                    ),
                );

                var osdLatestDate = new Date(
                    Math.max(
                        ...osdCorrespondingItem.models
                            .map((model: any) => new Date(model.date))
                            .concat(
                                osdCorrespondingItem.reports.map(
                                    (report: any) => new Date(report.date),
                                ),
                            ),
                    ),
                );

                var latestDate = Math.max(jcoLatestDate.getTime(), osdLatestDate.getTime());
                var mergedObj2 = {
                    addressString: item.addressString,
                    date: new Date(latestDate).toLocaleString('en-US', {
                        timeZone: 'Australia/Melbourne',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                    }),
                    su: item,
                    osd: osdCorrespondingItem,
                };
                mergedArray.push(mergedObj2);
            }
        });

        const remainingSUProjects = su
            .filter(function (element: any) {
                return !mergedProjectAddresses.includes(element.addressString);
            })
            .map((project: any) => ({
                addressString: project.addressString,
                date: new Date(project.date).toLocaleString('en-US', {
                    timeZone: 'Australia/Melbourne',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                }),
                su: project,
            }));

        const remainingJCOProjects = jco
            .filter(function (element: any) {
                return !mergedProjectAddresses.includes(element.addressString);
            })
            .map((project: any) => ({
                addressString: project.addressString,
                date: new Date(project.date).toLocaleString('en-US', {
                    timeZone: 'Australia/Melbourne',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                }),
                jco: project,
            }));

        const remainingOSDProjects = osd
            .filter(function (element: any) {
                return !mergedProjectAddresses.includes(element.addressString);
            })
            .map((project: any) => ({
                addressString: project.addressString,
                date: new Date(project.date).toLocaleString('en-US', {
                    timeZone: 'Australia/Melbourne',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                }),
                osd: project,
            }));

        return mergedArray
            .concat(remainingSUProjects)
            .concat(remainingJCOProjects)
            .concat(remainingOSDProjects);
    };

    useEffect(() => {
        getAllProjects();
    }, [idToken.email]);

    const handleFilter = (value: string) => {
        setFilter(value);
        const filteredProjects = projects.filter((project: any) => {
            const projectName = project.addressString?.toLowerCase();
            const projectDate = project.date.toLowerCase();
            const filterValue = value.toLowerCase();
            return projectName?.includes(filterValue) || projectDate.includes(filterValue);
        });

        setFilteredProjects(filteredProjects);
    };

    const handleSort = (property: string, sortOrder = 'desc') => {
        const sortedProjects = [...projects].sort((a, b) => {
            const valueA = property === 'date' ? new Date(a['date']) : a[property];
            const valueB = property === 'date' ? new Date(b['date']) : b[property];

            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
        console.log(sortedProjects);

        setOrderBy(property);
        setFilteredProjects(sortedProjects);
    };

    const renderProjectCards = () => {
        const rows = [];

        for (let i = 0; i < filteredProjects.length; i += 12) {
            const projectsInRow = filteredProjects.slice(i, i + 12);
            rows.push(
                <Grid container spacing={2} key={i} padding={{ xs: 0 }}>
                    {projectsInRow.map((project: any, index) => (
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            key={project.addressString + index}
                            sx={{ paddingTop: '0' }}
                            mb={3}
                        >
                            <ProjectCard project={project} handler={handleOpenProject} />
                        </Grid>
                    ))}
                </Grid>,
            );
        }
        return rows;
    };

    return (
        <Container sx={{ mt: 4, mb: 4 }}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                <Select
                    value={orderBy}
                    onChange={(e) =>
                        handleSort(
                            e.target.value as string,
                            e.target.value === 'date' ? 'desc' : 'asc',
                        )
                    }
                    sx={{ minWidth: '200px' }}
                    label="Sort by"
                >
                    {/* <MenuItem value="name">Project Name</MenuItem> */}
                    <MenuItem value="addressString">Project Address</MenuItem>
                    <MenuItem value="date">Last Modified Date</MenuItem>
                </Select>
            </FormControl>

            <TextField
                id="outlined-basic"
                label="Filter by"
                variant="outlined"
                onChange={(e) => handleFilter(e.target.value)}
                value={filter}
                sx={{ ml: 3 }}
            />

            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{ mt: 2 }}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Saved Projects</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {renderProjectCards()}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>SU Calculator projects</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={4} margin={4}>
                        {renderProjectCards()}
                    </Grid>
                </AccordionDetails>
            </Accordion> */}
        </Container>
    );
};

export default ProjectList;
