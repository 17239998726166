import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebarSlice';
import { accountTokenReducer } from './accountTokenSlice';
import { paymentReducer } from './paymentSlice';
import { addressAndMapReducer } from './addressAndMapSlice';
import { miscReducer } from './misc';

export const store = configureStore({
    reducer: {
        sidebar: sidebarReducer,
        accountToken: accountTokenReducer,
        payment: paymentReducer,
        addressAndMap: addressAndMapReducer,
        misc: miscReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
