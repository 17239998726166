import * as React from "react";
import Typography from "@mui/material/Typography";
import { buttonPriceList } from "./PriceList";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "../accordion/AccordionStyle";

const ListItems = (item: any, index: any) => {
    return (
        <Grid item xs={12} sm={6} key={index}>
            <List>
                <ListItem
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: 0,
                        pb: 0,
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography variant="body1" component="div">
                                {item.title}
                            </Typography>
                        }
                        sx={{ flex: 2 }}
                    />
                    <ListItemText
                        primary={
                            <Typography variant="body1" component="div">
                                ${item.price}
                            </Typography>
                        }
                        sx={{ flex: 1 }}
                    />
                </ListItem>
            </List>
        </Grid>
    );
};

export default function ButtonPriceList() {
    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                >
                    <Typography>
                        John Connor Online Price List
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {buttonPriceList.jco.map((item: any, index: number) =>
                            ListItems(item, index)
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                >
                    <Typography>SU Calculator Price List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {buttonPriceList.calculator.map(
                            (item: any, index: number) => ListItems(item, index)
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                >
                    <Typography>OSD Price List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {buttonPriceList.osd.map((item: any, index: number) =>
                            ListItems(item, index)
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
