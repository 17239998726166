import React, { useEffect } from 'react';

import '../../asset/css/training.css';

import { Button, Divider } from '@mui/material';
import cswTrainingImages from '../../asset/images/CSW Logo Training.png';
export const TrainingConfirmation = () => {
    const handleClick = () => {
        window.location.href = 'https://www.stormupdated.com.au/';
    };
    return (
        <>
            <div className="maintenance-page">
                <div className="registration-content">
                    <img
                        src={cswTrainingImages}
                        width={280}
                        alt="csw-logo"
                    />
                    <p className="complete-p">
                        Thank you for registering. A receipt invoice will be sent to your email
                        shortly. We will send the link to the online course closer towards the date.
                        Have a good one & see you soon!
                    </p>
                    <Divider />
                    <p>Go back to</p>
                    <Button onClick={handleClick}>Stormupdated.com.au</Button>
                </div>
            </div>
        </>
    );
};
