import React from "react";
import { Typography, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

const ConfirmationPage: React.FC = () => {
  return (
    <Container
      sx={{
        mt: 10,
        p: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom color={"green"}>
        Purchase Successful!
      </Typography>
      <Typography sx={{ mt: 5, mb: 5 }} variant="body1" gutterBottom>
        Thank you for your purchase. You can now view your subscription status
        or Storm Dollars in your account details.
      </Typography>
      <Button variant="contained" color="primary">
        <Link
          style={{ textDecoration: "none", color: "white" }}
          to="/personal-details"
        >
          View Account Details
        </Link>
      </Button>
    </Container>
  );
};

export default ConfirmationPage;
