import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { checkSDCredit, getAllSubscriptionStatus } from "../../dbquery/dbquery";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const toolNameMapping: any = {
  su: "SU Calculator",
  jco: "John Connor Online",
  osd: "OSD",
};
const ViewSubscription = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  const [sdCredit, setSDCredit] = useState(0);
  const user_id = useSelector(
    (state: any) => state.accountToken.idToken["cognito:username"],
  );

  const getSubscriptionStatus = async () => {
    const result = await getAllSubscriptionStatus(user_id);
    result.forEach((obj: any) => {
      obj.tool_name = toolNameMapping[obj.tool_name] || obj.tool_name;
    });
    setSubscriptionStatus(result);
    const sdCreditResult = await checkSDCredit(user_id);
    setSDCredit(
      sdCreditResult.length > 0 ? sdCreditResult[0].credit_amount : 0,
    );
  };

  useEffect(() => {
    getSubscriptionStatus();
  }, []);

  return (
    <Container sx={{ mt: "30px" }}>
      <Paper elevation={4} sx={{ mb: "10px", padding: "5px" }}>
        <Typography variant="h5" sx={{ mb: 2, mt: 1 }}>
          Subscription
        </Typography>
        <Divider />
        {subscriptionStatus.length > 0 ? (
          subscriptionStatus.map((sub: any, index: number) => (
            <Card key={index} sx={{ mb: "10px", p: "20px" }} elevation={0}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" component="h2">
                    {sub.tool_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box sx={{ marginRight: 2 }}>
                    <Typography color="textSecondary">Start Date:</Typography>
                    <Typography color="textSecondary">End Date:</Typography>
                  </Box>
                  <Box>
                    <Typography color="textSecondary">
                      {sub.start_date}
                    </Typography>
                    <Typography color="textSecondary">
                      {sub.end_date}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button variant="outlined" size="small">
                    <Link
                      style={{ textDecoration: "none", color: "#1976d2" }}
                      to="/subscription"
                    >
                      Renew Subscription
                    </Link>
                  </Button>
                </Grid>
              </Grid>
            </Card>
          ))
        ) : (
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ padding: "20px" }}
          >
            <Grid item xs={12} sm={8}>
              <Typography
                variant="h6"
                component="h2"
                sx={{ textAlign: "center", padding: "20px" }}
              >
                No Subscription
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="outlined" size="small">
                <Link
                  style={{ textDecoration: "none", color: "#1976d2" }}
                  to="/subscription"
                >
                  Activate Subscription
                </Link>
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
      <Paper elevation={4} sx={{ mt: "30px", mb: "10px", padding: "5px" }}>
        <Typography variant="h5" sx={{ mb: 2, mt: 1 }}>
          Storm Dollars
        </Typography>
        <Divider />
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{ padding: "20px" }}
        >
          <Grid item xs={12} sm={4}>
            <Typography color="textSecondary">
              Storm Dollars Remaining:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MonetizationOnIcon sx={{ mr: 1 }} color="success" /> {sdCredit}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: "10px" }}
              onClick={getSubscriptionStatus}
            >
              <Link
                style={{ textDecoration: "none", color: "#1976d2" }}
                to="/purchase-credits"
              >
                Purchase Storm Dollars
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ViewSubscription;
