import React from 'react';
import { Divider, MenuItem, List, ListItemText } from '@mui/material';
import SavedModelOperations from './SavedModelOperations';
import SavedReportOperations from './SavedReportOperations';
const SecondaryContextMenu = (props: {
    openSubMenu: any;
    project: any;
    handler: any;
    setOpenSubMenu: any;
    clickedMenu: any;
}) => {
    const { openSubMenu, setOpenSubMenu, clickedMenu } = props;
    const handleClickSubMenu = (e: any) => {
        if (openSubMenu === e.target.innerText) {
            setOpenSubMenu('');
            return;
        } else {
            setOpenSubMenu(e.target.innerText);
        }
    };

    return (
        <div>
            <List>
                {props.project[clickedMenu]?.models.map((model: any, index: number) => {
                    return (
                        <div key={index}>
                            <MenuItem
                                key={index}
                                onClick={(e) => handleClickSubMenu(e)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    minWidth: '176px',
                                }}
                            >
                                <ListItemText primary={model.saveName} />
                            </MenuItem>
                            <SavedModelOperations
                                clickedMenu={clickedMenu}
                                handler={props.handler}
                                model={model}
                                openSubMenu={openSubMenu}
                                project={props.project}
                            />
                        </div>
                    );
                })}
            </List>
            <Divider />
            <List>
                <MenuItem
                    onClick={(e) => handleClickSubMenu(e)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minWidth: '176px',
                    }}
                >
                    <ListItemText primary="View Report" />
                </MenuItem>
                <SavedReportOperations
                    openSubMenu={openSubMenu}
                    project={props.project}
                    clickedMenu={clickedMenu}
                />
            </List>{' '}
        </div>
    );
};

export default SecondaryContextMenu;
