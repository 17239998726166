import React from 'react';
import { Collapse, MenuItem, ListItemText } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { getFileFromS3 } from '../../dbquery/dbquery';
import { download } from '../../helper';

const SavedReportOperations = (props: { openSubMenu: any; project: any; clickedMenu: any }) => {
    const { openSubMenu, clickedMenu } = props;

    const getReportFromS3 = async (model_id: any, address: string) => {
        const foldersDict: { [key: string]: string } = {
            jco: 'jco',
            su: 'calculator',
            osd: 'osd',
        };
        try {
            const urlResponse = await getFileFromS3(
                'jco-user-reports',
                `${foldersDict[clickedMenu]}/${model_id}.pdf`,
            );

            const url = urlResponse.results;
            const reportResponse = await fetch(url);
            const reportBlob = await reportResponse.blob();
            const fileURL = URL.createObjectURL(reportBlob);
            download(fileURL, `${address}.pdf`);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    return (
        <div>
            <Collapse
                in={openSubMenu === 'View Report'}
                timeout="auto"
                unmountOnExit
                sx={{ maxHeight: '300px', overflow: 'auto' }}
            >
                {props.project[clickedMenu]?.reports.map((report: any, index: number) => {
                    return (
                        <MenuItem
                            onClick={() => getReportFromS3(report.model_id, report.addressString)}
                            key={index}
                        >
                            <DescriptionIcon sx={{ color: 'black', margin: '0 5px' }} />
                            <ListItemText primary={report.date} />
                        </MenuItem>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default SavedReportOperations;
