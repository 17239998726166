import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Container } from '@mui/material';
import PersonalDetails from './PersonalDetails';
import ViewSubscription from './ViewSubscription';
import ManageCompanyAccount from './ManageComponyAccount';
import { checkIsCompanyAdminAccount } from '../../dbquery/dbquery';
import { useSelector } from 'react-redux';
import ViewCreditUsage from './ViewCreditUsage';

const AccountPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [isAdminAccount, setIsAdminAccount] = useState<any>(false);
    const idToken = useSelector((state: any) => state.accountToken.idToken);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const fetchIsAdminAccount = async () => {
            const isAdmin = await checkIsCompanyAdminAccount(idToken.email);
            setIsAdminAccount(isAdmin);
        };
        fetchIsAdminAccount();
    }, [idToken.email]);

    return (
        <Container>
            <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="View Subscription and Storm dollars" />
                <Tab label="Personal Details" />
                <Tab label="View Credit Usage History" />
                {isAdminAccount && <Tab label="Manage Company account" />}
            </Tabs>
            {selectedTab === 0 && <ViewSubscription />}
            {selectedTab === 1 && <PersonalDetails />}
            {selectedTab === 2 && <ViewCreditUsage />}
            {selectedTab === 3 && <ManageCompanyAccount companyId={isAdminAccount.company_id} />}
        </Container>
    );
};

export default AccountPage;
