export async function downloadDrainsFiles(lat: number, lng: number, address: string) {
    try {
      // Make a request to your server
      const response = await fetch('https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/drain-files-download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "lon_coord": lng,
          "lat_coord": lat
        }),
      });
  
      // Parse the response
      const responseData = await response.json();
  
      // Check if the request was successful
      if (response.ok) {
        // Convert and download each file type
        downloadFile(responseData.BomCSV, `IFD 2016 Rainfall ${address} .csv`, 'text/csv');
        downloadFile(responseData.TempPatZip, 'ARR TemporaryPatterns.zip', 'application/zip');
        downloadFile(responseData.MedianTxt, 'ARR MedianPreburst.txt', 'text/plain');
      } else {
        // Handle errors
        console.error(responseData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  function downloadFile(base64Data: any, fileName: any, mimeType: any) {
    // Convert base64 data to Blob
    const blob = base64toBlob(base64Data, mimeType);
  
    // Create download link for the file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
  
    // Append the link to the DOM and trigger a click
    document.body.appendChild(downloadLink);
    downloadLink.click();
  
    // Remove the link from the DOM
    document.body.removeChild(downloadLink);
  }
  
  function base64toBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: mimeType });
  }