import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address, defaultAddress } from "../components/googleMap/MapInterface";

interface State {
  address: Address;
}

const initialState = {
  address: defaultAddress(),
};

const addressAndMapSlice = createSlice({
  name: "addressAndMap",
  initialState,
  reducers: {
    setAddress: (state: State, action: PayloadAction<any>) => {
      state.address = action.payload;
    },

    // You can add other actions related to the sidebar state here if needed
  },
});

export const { actions: addressAndMapActions, reducer: addressAndMapReducer } =
  addressAndMapSlice;
export default addressAndMapSlice.reducer;
