import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
    idToken: any;
    accessTokenJWT: any;
    refreshToken: any;
    isSubAccount: boolean;
    isAdminAccount: boolean;
}

const initialState = {
    idToken: { name: '' },
    accessTokenJWT: '',
    refreshToken: '',
    isSubAccount: false,
    isAdminAccount: false,
};

const accountTokenSlice = createSlice({
    name: 'accountToken',
    initialState,
    reducers: {
        addIdToken: (state: State, action: PayloadAction<any>) => {
            state.idToken = action.payload;
        },
        addAccessTokenJWT: (state: State, action: PayloadAction<any>) => {
            state.accessTokenJWT = action.payload;
        },
        addRefreshToken: (state: State, action: PayloadAction<any>) => {
            state.refreshToken = action.payload;
        },
        setIsSubAccount: (state: State, action: PayloadAction<boolean>) => {
            state.isSubAccount = action.payload;
        },
        setIsAdminAccount: (state: State, action: PayloadAction<boolean>) => {
            state.isAdminAccount = action.payload;
        },
        // You can add other actions related to the sidebar state here if needed
    },
});

export const { actions: accountTokenActions, reducer: accountTokenReducer } = accountTokenSlice;
export default accountTokenSlice.reducer;
