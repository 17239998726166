import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import { useSelector } from "react-redux";

function getInitials(name: string) {
  if (name) {
    const words = name.split(" ");
    if (words.length === 1) {
      // Single-word name, return the first character
      return name.charAt(0).toUpperCase();
    } else if (words.length >= 2) {
      // Multi-word name, take the first two characters of each word
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export default function UserAvatar() {
  const idToken = useSelector((state: any) => state.accountToken.idToken);
  return (
    <Stack direction="row" spacing={2}>
      <Avatar sx={{ bgcolor: purple[400], width: "38px", height: " 38px" }}>
        {getInitials(idToken["name"])}
      </Avatar>
    </Stack>
  );
}
