import { Routes, Route, useLocation } from 'react-router-dom';
//import Amplify
import { Amplify } from 'aws-amplify';
import { Authenticator, Button, Divider, Image, Text, View, useTheme } from '@aws-amplify/ui-react';
//import css
import './App.css';
import '@aws-amplify/ui-react/styles.css';
// npm//import img
import cleanstormwaterLogo from './asset/images/CSW Logo2.jpg';
//import components
import WebNavbar from './components/navbar/WebNavbar';
import WebSidebar from './components/sidebar/WebSidebar';
//import config
import { awsExports, formFields } from './signInRelated/aws-exports';
import Dashboard from './components/dashboard/DashBoard';
import PurchaseCredits from './components/purchaseAndCredit/PurchaseCredits';
import AccountPage from './components/personalAccInfo/AccountPage';
import ProjectHistory from './components/projectHistory/ProjectHistory';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import { accountTokenActions } from './redux/accountTokenSlice';
import { useEffect } from 'react';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { getAppendixToken, getModelId, getRedirect, toolUrls } from './helper';
import CheckoutForm from './components/payment/CheckoutForm';
import Return from './components/payment/Return';
import PricingPage from './components/purchaseAndCredit/StripeSubscription';
import PaymentSuccessPage from './components/payment/PaymentSuccessPage';
import {
    checkIsCompanyAdminAccount,
    checkIsCompanySubAccount,
    getTotoevAssets,
} from './dbquery/dbquery';
import CheckoutFormWithoutSignIn from './components/payment/CheckoutFormWithoutSignIn';
import { miscActions } from './redux/misc';
import TrainingContainer from './components/Training/TrainingContainer';

// Configure Amplify
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: awsExports.USER_POOL_ID,
            userPoolClientId: awsExports.USER_POOL_CLIENT_ID,
        },
    },
});

function App() {
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const model_id = getModelId(search);
    const redirect = getRedirect(search);
    const appendixToken = getAppendixToken(search);
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);
    const refreshToken = useSelector((state: any) => state.accountToken.refreshToken);
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    
    const handleRedirectToTool = (model_id: string, redirect: null | string, guest: boolean) => {
        if (appendixToken) {
            const appendix = atob(appendixToken);
            const queryParams = new URLSearchParams(appendix);

            const domainStartIndex = appendix.indexOf('redirect=') + 'redirect='.length;
            const extractedDomain = appendix.slice(domainStartIndex);

            let url = extractedDomain + '?';
            const params = [`token=${accessTokenJWT}`, `refresh=${refreshToken}`];
            queryParams.forEach((value, key) => {
                params.push(`${key}=${value}`);
            });
            window.open(url + params.join('&&'), '_self');
        }
        if (redirect && model_id && accessTokenJWT) {
            const appendix = `?model_id=${model_id}`;
            window.open(
                `${toolUrls[redirect]}${appendix}&&token=${accessTokenJWT}&&refresh=${refreshToken}`,
                '_self',
            );
        } else if (model_id && guest) {
            const appendix = `?model_id=${model_id}`;
            window.open(
                `https://www.jco-test.stormupdated.com.au/${appendix}&&token=guest`,
                '_self',
            );
        }
    };

    const components = {
        Header() {
            const { tokens } = useTheme();
            const guestSignInButton = !model_id ? null : (
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyItems: 'center',
                    }}
                >
                    <Button
                        color={tokens.colors.neutral[80]}
                        onClick={() => handleRedirectToTool(model_id, redirect, true)}
                    >
                        Guest Sign in{' '}
                        <span style={{ fontWeight: '400', marginLeft: '10px' }}>
                            (limited access to tools)
                        </span>
                    </Button>
                    <Divider style={{ margin: '20px 0' }} />
                </View>
            );

            return (
                <>
                    <View textAlign="center" padding={tokens.space.large}>
                        <Image alt="Storm Gate Portal Logo" src={cleanstormwaterLogo} />
                    </View>
                    {guestSignInButton}
                </>
            );
        },
        Footer() {
            const { tokens } = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
                </View>
            );
        },
    };
    //fetch authentication session data
    useEffect(() => {
        async function currentAuthenticatedUser() {
            try {
                const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
                dispatch(accountTokenActions.addIdToken(idToken?.payload));
                const storeJWTInLocalStorage = () => {
                    cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
                    const idTokenKey = Object.keys(defaultStorage.storage!).filter((item) =>
                        item.includes('idToken'),
                    )[0];
                    const refreshTokenKey = Object.keys(defaultStorage.storage!).filter((item) =>
                        item.includes('refreshToken'),
                    )[0];
                    const idToken = defaultStorage.storage![idTokenKey];
                    const refreshToken = defaultStorage.storage![refreshTokenKey];
                    if (accessToken) {
                        dispatch(accountTokenActions.addAccessTokenJWT(idToken));
                        dispatch(accountTokenActions.addRefreshToken(refreshToken));
                    }
                };
                storeJWTInLocalStorage();
            } catch (err) {
                console.log(err);
            }
        }
        currentAuthenticatedUser();
    }, [authStatus]);

    useEffect(() => {
        async function checkUserType() {
            const isAdmin = await checkIsCompanyAdminAccount(idToken.email);
            const isSub = await checkIsCompanySubAccount(idToken.email);
            dispatch(accountTokenActions.setIsAdminAccount(isAdmin));
            dispatch(accountTokenActions.setIsSubAccount(isSub));
        }
        if (authStatus === 'authenticated') {
            checkUserType();
        }
    }, [idToken]);

    useEffect(() => {
        if (authStatus === 'authenticated' && model_id) {
            handleRedirectToTool(model_id, redirect, false);
        }
    }, [authStatus, model_id]);
    useEffect(() => {
        if (authStatus === 'authenticated' && appendixToken) {
            handleRedirectToTool('', null, false);
        }
    }, [authStatus, appendixToken]);
    useEffect(() => {
        getTotoevAssets('bonusSDMessage').then((message: string) => {
            dispatch(miscActions.setBonusSDMessage(message));
        });
    }, []);

    return (
        <>
            <Routes>
                <Route path="/training" element={<TrainingContainer />} />
                <Route path="/trainingCheckout" element={<CheckoutFormWithoutSignIn />} />
            </Routes>

            {!pathname.startsWith('/training') && !pathname.startsWith('/trainingCheckout') && (
                <Authenticator
                    loginMechanisms={['email']}
                    signUpAttributes={['email', 'name']}
                    formFields={formFields}
                    components={components}
                >
                    {({ signOut, user }) => (
                        <div>
                            <WebNavbar signOut={signOut} />
                            <WebSidebar />
                            <div className="main-content">
                                <Routes>
                                    <Route path="*" element={<Dashboard />} />
                                    <Route path="/personal-details" element={<AccountPage />} />
                                    <Route path="/purchase-credits" element={<PurchaseCredits />} />
                                    <Route path="/project-history" element={<ProjectHistory />} />
                                    <Route path="/checkout" element={<CheckoutForm />} />
                                    <Route path="/return" element={<Return />} />
                                    <Route path="/subscription" element={<PricingPage />} />
                                    <Route
                                        path="/payment-success"
                                        element={<PaymentSuccessPage />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    )}
                </Authenticator>
            )}
        </>
    );
}

export default App;
