export const awsExports = {
    REGION: 'ap-southeast-2',
    USER_POOL_ID: 'ap-southeast-2_Wan7TRmMY',
    USER_POOL_CLIENT_ID: '66qvcni6rjq7nd3kdr7hlh6d2b',
};
export const formFields = {
    signUp: {
        email: {
            order: 1,
            isRequired: true,
        },
        name: {
            order: 2,
            isRequired: true,
        },
        password: {
            order: 5,
            isRequired: true,
        },
        confirm_password: {
            order: 6,
            isRequired: true,
        },
    },
};
