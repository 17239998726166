export const containerStyle = {
    minHeight: '566px',
};

export const stateCoordinates = {
    ACT: {
        center: { lat: -35.42995093240622, lng: 149.0282702905103 },
        zoom: 6,
    },
    NSW: {
        center: { lat: -32.186360053101396, lng: 148.77494312266262 },
        zoom: 6,
    },
    NT: {
        center: { lat: -18.398986846262332, lng: 133.72803718845648 },
        zoom: 6,
    },
    QLD: {
        center: { lat: -19.617333739656416, lng: 145.8990016598991 },
        zoom: 6,
    },
    SA: {
        center: { lat: -30.265556751610323, lng: 136.05253052267014 },
        zoom: 6,
    },
    TAS: {
        center: { lat: -41.93069837065246, lng: 146.77890262469748 },
        zoom: 6,
    },
    VIC: {
        center: { lat: -36.89938739551902, lng: 144.85531304687504 },
        zoom: 6,
    },
    WA: {
        center: { lat: -24.91318170386305, lng: 119.52909302267012 },
        zoom: 6,
    },
    NZ: {
        center: { lat: -40.9006, lng: 174.886 },
        zoom: 5,
    },
    ROM: {
        center: { lat: 45.8447, lng: 24.9678 },
        zoom: 6,
    }
};

export const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    draggableCursor: 'pointer',
};

export const S3_FILE_PATH = 'https://suwares.s3.ap-southeast-2.amazonaws.com';
