import React from 'react';
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';

const Search = (props: any) => {
    return (
        <div>
            <DatalistInput
                placeholder={props.placeholder}
                label=""
                onSelect={(x) => props.handleSelect(x.value)}
                onChange={props.handleInput}
                items={(props.data as Array<{ place_id: string; description: string }>).map(
                    ({ place_id, description }) => {
                        return { id: place_id, value: description };
                    },
                )}
            />
        </div>
    );
};

export default Search;
