import { Container } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function PricingPage() {
  const idToken = useSelector((state: any) => state.accountToken.idToken);

  return (
    <Container sx={{ mt: 10 }}>
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1OqaWsCV2KLb3XCkkOzecFGQ"
        publishable-key="pk_test_51OpLi0CV2KLb3XCk8Dz7abXa4IOyndoLuEgH3afLEGwR5zR0s8ajFzqRcSRo25OgBcUNKaQVFA6u0g8a5DhFUojp00CjQ3o3LG"
        customer-email={idToken.email}
      ></stripe-pricing-table> */}
      <stripe-pricing-table
        pricing-table-id="prctbl_1OqP2OCV2KLb3XCkWRaYLzR0"
        publishable-key="pk_live_51OpLi0CV2KLb3XCkPUYbcY8qP1qdOx4onMEuR7rEHLgfVXEPzaDWMlVtrF315U2JzeXJ4WpQ5e1QZNsywSFM3Piz00O8pv82qe"
        customer-email={idToken.email}
      ></stripe-pricing-table>
    </Container>
  );
}

export default PricingPage;
