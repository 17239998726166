import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(
  // "pk_test_51OpLi0CV2KLb3XCk8Dz7abXa4IOyndoLuEgH3afLEGwR5zR0s8ajFzqRcSRo25OgBcUNKaQVFA6u0g8a5DhFUojp00CjQ3o3LG"
  "pk_live_51OpLi0CV2KLb3XCkPUYbcY8qP1qdOx4onMEuR7rEHLgfVXEPzaDWMlVtrF315U2JzeXJ4WpQ5e1QZNsywSFM3Piz00O8pv82qe"
);
const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const amountToPay = useSelector((state: any) => state.payment.amountToPay);
  const trainingSessionData = useSelector((state: any) => state.payment.trainingSessionData);
  const specialCoupon = useSelector(
    (state: any) => state.payment.specialCoupon
  );
  const idToken = useSelector((state: any) => state.accountToken.idToken);
  const payload = amountToPay > 0 ? {
    amount: amountToPay * 100,
    email: idToken.email,
    host: window.location.host,
    coupon: specialCoupon,
  } : trainingSessionData.sessionName !== "" ? {
    trainingSession: trainingSessionData.sessionName,
    email: idToken.email,
    host: window.location.host,
    token: trainingSessionData.token,
  } : null;
  useEffect(() => {
    fetch("https://toglm12jl3.execute-api.ap-southeast-2.amazonaws.com/test", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <Container sx={{ mt: 3 }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Container>
      )}
    </div>
  );
};

export default CheckoutForm;
