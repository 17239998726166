import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { rechargeSDCredit, updateCouponUsedHistory } from '../../dbquery/dbquery';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ConfirmationPage from './Confirmationpage';
import { miscActions } from '../../redux/misc';

const Return = () => {
    const dispatch = useDispatch();
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const [urlParams, setUrlParams] = useState<any>(null);
    const [paymentStatus, setPaymentStatus] = useState<{
        status: string;
        product: string;
        payment_intent: string;
        customer_email: string;
        amount_total: number;
        amount_subtotal: number;
        amount_discount: number;
    }>({
        status: '',
        product: '',
        payment_intent: '',
        customer_email: '',
        amount_total: 0,
        amount_subtotal: 0,
        amount_discount: 0,
    });

    useEffect(() => {
        const fetchPaymentStatus = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            setUrlParams(urlParams);
            const sessionId = urlParams.get('session_id');
            try {
                const response = await fetch(
                    `https://toglm12jl3.execute-api.ap-southeast-2.amazonaws.com/test`,
                    {
                        method: 'POST',
                        body: JSON.stringify({ sessionId: sessionId }),
                    },
                );
                const data = await response.json();
                setPaymentStatus(data);
            } catch (error) {
                console.error('An error occurred while fetching payment status:', error);
            }
        };
        fetchPaymentStatus();
    }, []);

    useEffect(() => {
        const handlePaymentStatus = async () => {
            if (paymentStatus.status === 'complete') {
                await rechargeSDCredit(
                    idToken['cognito:username'],
                    paymentStatus.payment_intent,
                    paymentStatus.amount_subtotal / 100,
                    paymentStatus.amount_total / 100,
                );

                const formData = new FormData();
                formData.append('endpoint', 'sendUserNewPaymentNotification');
                formData.append(
                    'params',
                    JSON.stringify({
                        product: paymentStatus.product,
                        amount_total: paymentStatus.amount_total / 100,
                        amount_subtotal: paymentStatus.amount_subtotal / 100,
                        customer_email: paymentStatus.customer_email,
                        name: idToken.name,
                    }),
                );
                try {
                    await axios.post(
                        'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/emailer',
                        formData,
                    );
                } catch (error) {
                    console.error('An error occurred while sending payment notification:', error);
                }

                if (paymentStatus.amount_discount) {
                    dispatch(miscActions.setBonusSDMessage(''));
                    await updateCouponUsedHistory(
                        paymentStatus.customer_email,
                        urlParams.get('coupon'),
                    );
                }
            }
        };
        handlePaymentStatus();
    }, [paymentStatus.status]);

    if (paymentStatus.status === 'open') {
        return <Navigate to="/checkout" />;
    }

    if (paymentStatus.status === 'complete') {
        return <ConfirmationPage />;
    }

    return null;
};

export default Return;
