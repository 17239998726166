import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  TextField,
  Button,
  Container,
  Alert,
  Grid,
  Snackbar,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePassword,
  updateUserAttribute,
  fetchAuthSession,
  type UpdateUserAttributeOutput,
} from "aws-amplify/auth";
import { accountTokenActions } from "../../redux/accountTokenSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { getImageUrlFromS3, getUserNameAndId, uploadToS3 } from "../../dbquery/dbquery";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const PersonalDetails = () => {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newName, setNewName] = useState("");
  const [showChangeNameSuccess, setShowChangeNameSuccess] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [originalImageUrl, setOriginalImageUrl] = useState("");
  const [newImageUrl, setNewImageUrl] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [showChangePasswordSuccess, setShowChangePasswordSuccess] =
    useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [companyLogoName, setCompanyLogoName] = useState("");
  const dispatch = useDispatch();
  const idToken = useSelector((state: any) => state.accountToken.idToken);
  const isAdminAccount = useSelector(
    (state: any) => state.accountToken.isAdminAccount,
  );
  const isSubAccount = useSelector(
    (state: any) => state.accountToken.isSubAccount,
  );
  const handleUpdatePassword = async () => {
    try {
      await updatePassword({ oldPassword, newPassword });
      setShowChangePasswordSuccess(true);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      const errorMessage = (err as Error)?.message || "An error occurred.";
      setErrMsg(errorMessage);
    }
  };

  const handleUpdateUserAttribute = async (
    attributeKey: string,
    value: string,
  ) => {
    try {
      const output = await updateUserAttribute({
        userAttribute: { attributeKey, value },
      });
      handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
      console.log(error);
    }
  };

  const currentAuthenticatedUser = async () => {
    try {
      const { accessToken, idToken } =
        (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
      dispatch(accountTokenActions.addIdToken(idToken?.payload));
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateUserAttributeNextSteps = (
    output: UpdateUserAttributeOutput,
  ) => {
    const { nextStep } = output;
    switch (nextStep.updateAttributeStep) {
      case "CONFIRM_ATTRIBUTE_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`,
        );
        break;
      case "DONE":
        console.log(`attribute was successfully updated.`);
        currentAuthenticatedUser();
        setShowChangeNameSuccess(true);
        setNewName("");
        break;
    }
  };
  useEffect(() => {
    const fetchImages = async () => {
      let fileName;
      if (isAdminAccount) {
        fileName = `${idToken.email.split("@")[1].replace(/\./g, "")}.png`;
      } else {
        fileName = `${idToken.email}.png`;
      }
      setCompanyLogoName(fileName);
      try {
        const url = await getImageUrlFromS3("company-icon", fileName);
        await axios.get(url);
        setOriginalImageUrl(url);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    if (originalImageUrl.length === 0) {
      fetchImages();
    }
  }, []);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setNewImageUrl(url);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };
  const handleConfirm = async (file: any) => {
    let fileName;
    if (isSubAccount) return;
    try {
      fetch(newImageUrl).then(async (response) => {
        if (isAdminAccount) {
          fileName = `${idToken.email.split("@")[1].replace(/\./g, "")}.png`;
        } else {
          fileName = `${idToken.email}.png`;
        }
        try {
          await uploadToS3(await response.blob(), fileName, "company-icon");
          openSnackBar();
          setSnackBarMessage("Successfully uploaded image to S3");
          setCompanyLogoName(fileName);
        } catch (error: any) {
          console.log("Failed to upload image to S3");
          setSnackBarMessage(
            "Failed to upload image to S3, please check the internet and ensure the image size is less than 5MB",
          );
          openSnackBar();
        }
      });
    } catch (error) {
      console.log("Failed to save background imag");
    }
  };
  const handleDelete = async () => {
    const formData = new FormData();
    formData.append(
      "params",
      JSON.stringify({
        bucket: "company-icon",
        filename: `${companyLogoName}`,
      }),
    );
    formData.append("endpoint", "deleteFileFromS3");
    try {
      const response = await axios.post(
        "https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/s3",
        formData,
      );
      setImage(null);
      setNewImageUrl("");
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  const openSnackBar = () => {
    setSnackBarOpen(true);
  };

  const handleEmailChange = (event: any) => {
    setAdminEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setAdminPassword(event.target.value);
  };

  const handleAdminLogin = async() => {
    const results = await getUserNameAndId(adminEmail, adminPassword); 
    let result;
    if (results.length > 0) {
      result = results[0];
    } else {
      return;
    }
    dispatch(accountTokenActions.addIdToken({...idToken, email: result.user_email, name: result.user_name, sub: result.user_id, "cognito:username": result.user_id}));
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: "50px" }}>
      <Paper elevation={3} style={{ padding: 20, marginTop: "20px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" gutterBottom>
              Personal Details
            </Typography>
            <TextField
              label="Account Name"
              fullWidth
              disabled
              margin="normal"
              variant="outlined"
              value={idToken.name || ""}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Edit Account Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <TextField
              label="Email"
              fullWidth
              disabled
              margin="normal"
              variant="outlined"
              value={idToken.email}
              InputProps={{ readOnly: true }}
            />
            {showChangeNameSuccess && (
              <Alert severity="success" sx={{ mt: "5px" }}>
                You have successfully changed Account Name
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleUpdateUserAttribute("name", newName)}
              disabled={!newName}
              style={{ marginTop: 20 }}
            >
              Change Account Name
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" gutterBottom>
              Change Password
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="Old Password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirm New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            {showChangePasswordSuccess && (
              <Alert severity="success" sx={{ mt: "5px" }}>
                You have succesfully changed password
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleUpdatePassword}
              disabled={!newPassword || newPassword !== confirmPassword}
              style={{ marginTop: 20 }}
            >
              Change Password
            </Button>
          </Grid>
          {!isSubAccount && (
            <Grid item xs={12} md={6}>
              <Typography variant="h5" align="center" gutterBottom>
                Manage Logo
              </Typography>
              <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ margin: "15px" }}
              >
                <i>
                  You can manage your logo here, the logo will be displayed in
                  the report that you download
                </i>
              </Typography>
              <Snackbar
                open={snackBarOpen}
                autoHideDuration={7000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  severity={
                    snackBarMessage === "Successfully uploaded image to S3"
                      ? "success"
                      : "error"
                  }
                >
                  {snackBarMessage}
                </Alert>
              </Snackbar>
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item md={3} xs={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ marginRight: "5px" }}
                    onClick={handleConfirm}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item md={3} xs={3}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={{ marginRight: "5px" }}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
              {image ? (
                <div>
                  <img
                    src={image}
                    alt="company logo1"
                    style={{ maxWidth: "100%", marginTop: 20 }}
                  />
                </div>
              ) : originalImageUrl ? (
                <div>
                  <img
                    src={originalImageUrl}
                    alt="company logo"
                    style={{ maxWidth: "100%", marginTop: 20 }}
                  />
                </div>
              ) : (
                <></>
              )}
            </Grid>
          )}
          {idToken["cognito:groups"] &&
            idToken["cognito:groups"].includes("admin") && (
              <Grid item xs={12} md={6}>
                <TextField 
                  label="Admin"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="password"
                  onChange={handleEmailChange}
                />
                <TextField 
                  label="Password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="password"
                  onChange={handlePasswordChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 20 }}
                  onClick={handleAdminLogin}
                >
                  Admin
                </Button>
              </Grid>
            )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default PersonalDetails;
