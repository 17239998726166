// misc.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
    bonusSDMessage: string;
}

const initialState = {
    bonusSDMessage: '',
};

const misc = createSlice({
    name: 'misc',
    initialState,
    reducers: {
        setBonusSDMessage: (state: State, action: PayloadAction<string>) => {
            state.bonusSDMessage = action.payload;
        },
    },
});

export const { actions: miscActions, reducer: miscReducer } = misc;
export default misc.reducer;
