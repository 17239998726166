import React from 'react';
import { Collapse, ListItemButton, Tooltip, List, ListItemIcon } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import { download, htmlTemplate, toolUrls } from '../../helper';
import DescriptionIcon from '@mui/icons-material/Description';
import { useSelector } from 'react-redux';

const SavedModelOperations = (props: {
    clickedMenu: any;
    handler: any;
    model: any;
    openSubMenu: any;
    project: any;
}) => {
    const { clickedMenu, model, openSubMenu } = props;
    const downloadHtml = (model_id: any, project_address: string) => {
        const htmlFile = new Blob([htmlTemplate(model_id, toolUrls[clickedMenu])], {
            type: 'text/plain',
        });
        const fileURL = URL.createObjectURL(htmlFile as Blob);
        download(fileURL, `${project_address}.html`);
    };
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);
    return (
        <div>
            <Collapse in={openSubMenu === model.saveName} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ display: 'flex' }}>
                    <Tooltip
                        title="Click to download html file."
                        placement="top"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <ListItemButton sx={{ padding: '5px 0px' }}>
                            <ListItemIcon sx={{ pl: 2 }}>
                                <DownloadIcon
                                    onClick={() => {
                                        downloadHtml(model.model_id, model.addressString);
                                    }}
                                    sx={{ color: 'black' }}
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                    <Tooltip
                        title="Click to open this saved project."
                        placement="top"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <ListItemButton sx={{ padding: '5px 0px' }}>
                            <ListItemIcon sx={{ pl: 2 }}>
                                <OpenInNewIcon
                                    sx={{ color: 'black' }}
                                    onClick={() => {
                                        props.handler(
                                            clickedMenu,
                                            props.project,
                                            accessTokenJWT,
                                            model.saveName,
                                        );
                                    }}
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                    <Tooltip
                        title="Click to download msf."
                        placement="top"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <ListItemButton sx={{ padding: '5px 0px' }} disabled>
                            <ListItemIcon sx={{ pl: 2 }}>
                                <DescriptionIcon sx={{ color: 'black' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </List>
            </Collapse>
        </div>
    );
};

export default SavedModelOperations;
