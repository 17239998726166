// sidebarSlice.js
import { createSlice } from "@reduxjs/toolkit";

interface State {
  collapsed: boolean;
  minimized: boolean;
  minimizedOpen: boolean
}

const initialState = {
  collapsed: false,
  minimized: false,
  minimizedOpen: false
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleCollapse: (state: State) => {
      state.collapsed = !state.collapsed;
    },
    toggleMinimize: (state: State) => {
      state.minimized = !state.minimized;
    },
  
    toggleMinimizedOpen: (state: State) => {
      state.minimizedOpen = !state.minimizedOpen;
    },
  
    // You can add other actions related to the sidebar state here if needed
  },
});

export const { actions: sidebarActions, reducer: sidebarReducer } =
  sidebarSlice;
export default sidebarSlice.reducer;
