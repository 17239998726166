import React, { useState } from 'react';
import { Box, Card, CardContent, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SecondaryContextMenu from './SecondaryContextMenu';

export default function ProjectCard(props: {
    project: any;
    handler: (project: any, accessTokenJWT: any, name?: string) => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
    const [openSubMenu, setOpenSubMenu] = React.useState('');
    const [clickedMenu, setClickedMenu] = useState<string>('');
    const open = Boolean(anchorEl);
    const handleSecondaryClose = () => {
        setSecondaryAnchorEl(null);
    };
    const handleSecondaryClick = (event: any) => {
        switch (event.target.innerText.trim()) {
            case 'John Connor Online':
                setClickedMenu('jco');
                break;
            case 'SU Calculator':
                setClickedMenu('su');
                break;
            case 'Osds4Vips':
                setClickedMenu('osd');
                break;
            default:
                setClickedMenu('');
                break;
        }
        setSecondaryAnchorEl(event.currentTarget);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenSubMenu('');
    };
    return (
        <Card
            sx={{
                mt: 2,
                ml: 1.5,
                minHeight: '166px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
            }}
            style={{ marginTop: '10px' }}
            elevation={4}
        >
            <Tooltip title="Click to view project templates." placement="top">
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreHorizIcon
                        color="primary"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    />
                </IconButton>
            </Tooltip>
            <CardContent sx={{ minHeight: { xs: '176px', sm: '176px', md: '136px' }, pt: '0px' }}>
                {/* <h4>{props.project.name}</h4> */}
                <p>Address: {props.project.addressString}</p>
                <p>Last Modified Date: {props.project.date}</p>
            </CardContent>
            <Box>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ minWidth: '200px' }}
                >
                    <MenuItem onClick={handleSecondaryClick} disabled={!props.project.jco}>
                        John Connor Online
                    </MenuItem>
                    <MenuItem onClick={handleSecondaryClick} disabled={!props.project.su}>
                        SU Calculator
                    </MenuItem>
                    <MenuItem onClick={handleSecondaryClick} disabled={!props.project.osd}>
                        Osds4Vips
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={secondaryAnchorEl}
                    open={Boolean(secondaryAnchorEl)}
                    onClose={handleSecondaryClose}
                >
                    <SecondaryContextMenu
                        openSubMenu={openSubMenu}
                        project={props.project}
                        handler={props.handler}
                        setOpenSubMenu={setOpenSubMenu}
                        clickedMenu={clickedMenu}
                    />
                </Menu>
            </Box>
        </Card>
    );
}
