'use client';

import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

// Import your training pages
import TrainingTemplate from './Template1';
import TrainingTemplate2 from './Template2';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const TrainingContainer = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ margin: '5vh 10vw', overflowX: 'hidden' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="training tabs">
                    {/* <Tab label="Melbourne Water Music Modelling Guidelines" {...a11yProps(0)} /> */}
                    <Tab label="WSUD Concepts and Modelling" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TrainingTemplate2 />
            </TabPanel>
            {/* <TabPanel value={value} index={0}>
                <TrainingTemplate />
            </TabPanel> */}
        </Box>
    );
};

export default TrainingContainer;
