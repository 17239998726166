import * as React from 'react';
//import states from redux toolkit
import { useDispatch, useSelector } from 'react-redux';
import { sidebarActions } from '../../redux/sidebarSlice';
//import components from mui
import {
    Box,
    IconButton,
    Menu,
    Toolbar,
    Badge,
    MenuItem,
    Tooltip,
    ListItemIcon,
    Avatar,
    Container,
    Button,
} from '@mui/material';
//import icons
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Logout, Settings } from '@mui/icons-material';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import images
import cleanstormwaterLogo from '../../asset/images/CSW Logo.png';
//import component
import UserAvatar from './UserAvatar';
//import router-dom
import { Link, useNavigate } from 'react-router-dom';
import { paymentActions } from '../../redux/paymentSlice';
import { checkCouponAvailability } from '../../dbquery/dbquery';

export default function WebNavbar(props: { signOut: any }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const [hasFirstUserCoupon, setHasFirstUserCoupon] = React.useState<boolean>(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const dispatch = useDispatch();
    const isSidebarMinimized = useSelector((state: any) => state.sidebar.minimized);
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const bonusSDMessage = useSelector((state: any) => state.misc.bonusSDMessage);

    const handleMinimizeOpenSidebar = () => {
        dispatch(sidebarActions.toggleMinimizedOpen());
    };
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleProceedToCheckout = (amount: number, specialCoupon: string) => {
        dispatch(paymentActions.setAmountToPay(amount));
        dispatch(paymentActions.setPaymentType('sdCredit'));
        if (specialCoupon) {
            dispatch(paymentActions.setSpecialCoupon(specialCoupon));
        } else {
            dispatch(paymentActions.setSpecialCoupon(''));
        }
        navigate('/checkout');
    };
    React.useEffect(() => {
        const checkFirstUserCouponAvailability = async () => {
            const coupon = 'Jw32KCIX';
            const response = await checkCouponAvailability(idToken.email, coupon);
            if (response) {
                setHasFirstUserCoupon(true);
            }
        };
        checkFirstUserCouponAvailability();
    }, []);
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Container sx={{ width: '250px', backgroundColor: 'red' }}>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <Link to="/personal-details" style={{ textDecoration: 'none', color: 'black' }}>
                    <MenuItem>
                        <ListItemIcon>
                            <Avatar sx={{ width: '20px', height: ' 20px' }} />
                        </ListItemIcon>
                        Account
                    </MenuItem>
                </Link>

                <MenuItem disabled>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>

                <MenuItem onClick={props.signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sign out
                </MenuItem>
            </Menu>
        </Container>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem disabled>
                <IconButton size="large" color="inherit">
                    <Badge badgeContent={0} color="error">
                        <WbIncandescentOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem disabled>
                <IconButton size="large" color="inherit">
                    <Badge badgeContent={0} color="error">
                        <NotificationsOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <PermIdentityIcon />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div style={{ position: 'static', backgroundColor: '#73cddf' }}>
                <Toolbar>
                    {isSidebarMinimized && (
                        <IconButton
                            size="medium"
                            aria-label="show 4 new mails"
                            color="inherit"
                            onClick={handleMinimizeOpenSidebar}
                        >
                            <MenuTwoToneIcon />
                        </IconButton>
                    )}
                    <Link to={'https://www.stormupdated.com.au/'}>
                        <img
                            src={cleanstormwaterLogo}
                            style={{ width: '210px' }}
                            alt="Cleanstormwater"
                        />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    {hasFirstUserCoupon && bonusSDMessage && (
                        <>
                            <Box>
                                <span style={{ marginRight: '5px' }}>{bonusSDMessage}</span>
                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={() => handleProceedToCheckout(160, 'Jw32KCIX')}
                                >
                                    <span style={{ fontWeight: 'bold' }}>Redeem Now!</span>
                                </Button>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                        </>
                    )}

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="show 4 new mails"
                            color="inherit"
                            disabled
                        >
                            <Badge badgeContent={0} color="error">
                                <WbIncandescentOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            disabled
                        >
                            <Badge badgeContent={0} color="error">
                                <NotificationsOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <Box sx={{ flexGrow: 0, ml: '10px' }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ margin: 'auto' }}>
                                    <UserAvatar />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '50px', width: '250px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Link
                                    to="/personal-details"
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                    <MenuItem sx={{ mb: '5px' }}>
                                        <ListItemIcon>
                                            <AccountCircleIcon fontSize="small" color="primary" />
                                        </ListItemIcon>
                                        Account
                                    </MenuItem>
                                </Link>
                                <MenuItem sx={{ mb: '5px' }} disabled>
                                    <ListItemIcon>
                                        <LoyaltyIcon fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    View Coupons
                                </MenuItem>

                                <MenuItem sx={{ mb: '5px' }} disabled>
                                    <ListItemIcon>
                                        <Settings fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>

                                <MenuItem onClick={props.signOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    Sign out
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </div>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
