import { useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import { openInNewTab, shuffleArray } from '../../helper';
import { getImageUrlFromS3, getPopUpAd } from '../../dbquery/dbquery';

const FeaturesTool = () => {
    const [userGuideFeaturesToDisplay, setUserGuideFeaturesToDisplay] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const modalData = await getPopUpAd('UP').then((response) => response.data);
                const data = await Promise.all(
                    modalData?.map(async (item: any) => {
                        if (item.image) {
                            const imageUrl = await getImageUrlFromS3(
                                'tool-user-guide',
                                `${item.image}`,
                            );
                            return { ...item, imageUrl };
                        }
                        return item;
                    }),
                );
                setUserGuideFeaturesToDisplay(shuffleArray(data));
            } catch (error) {
                console.error('Error fetching user guide features:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {userGuideFeaturesToDisplay.length > 0 && (
                <Container>
                    {userGuideFeaturesToDisplay.map((item: any) => (
                        <div key={item.id}>
                            <div
                                style={{
                                    height: '35px',
                                    background: 'rgb(210, 210, 210)',
                                    textAlign: 'center',
                                }}
                            >
                                <p style={{ lineHeight: '35px' }}>{item.title}</p>
                            </div>
                            <img src={item['imageUrl']} style={{ maxWidth: '100%' }} alt="" />
                            <div
                                style={{
                                    height: '200px',
                                    padding: '20px',
                                    backgroundColor: 'rgb(55, 152, 187)',
                                }}
                            >
                                <p style={{ whiteSpace: 'pre-line' }}>{item.description}</p>

                                {item.link && (
                                    <div style={{ marginTop: '3vh' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => openInNewTab(item.link)}
                                            color="secondary"
                                        >
                                            Link
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </Container>
            )}
        </>
    );
};

export default FeaturesTool;
