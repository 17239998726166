import React, { useEffect, useState } from 'react';
import {
    Button,
    ToggleButton,
    ToggleButtonGroup,
    TextField,
    Typography,
    Box,
    Divider,
    InputAdornment,
    Container,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { paymentActions } from '../../redux/paymentSlice';
import { useNavigate } from 'react-router-dom';
import { checkCouponAvailability } from '../../dbquery/dbquery';

export default function ChargeCredit() {
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [customAmount, setCustomAmount] = useState<number | null>(null);
    const [hasFirstUserCoupon, setHasFirstUserCoupon] = useState<boolean>(false);
    const bonusSDMessage = useSelector((state: any) => state.misc.bonusSDMessage);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const idToken = useSelector((state: any) => state.accountToken.idToken);

    const handleAmountChange = (event: React.MouseEvent<HTMLElement>, newAmount: number | null) => {
        setSelectedAmount(newAmount);
        setCustomAmount(null);
    };

    const handleCustomAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value.replace(/e/g, '');
        inputValue = inputValue.replace(/[^0-9.0-9]/g, '');
        const parts = inputValue.split('.');
        if (parts.length > 1) {
            parts[1] = parts[1].slice(0, 2);
            inputValue = parts.join('.');
        }
        setCustomAmount(Number(inputValue));
        setSelectedAmount(null);
    };

    const handleProceedToCheckout = (amount: number, specialCoupon: string) => {
        dispatch(paymentActions.setAmountToPay(amount));
        dispatch(paymentActions.setPaymentType('sdCredit'));
        if (specialCoupon) {
            dispatch(paymentActions.setSpecialCoupon(specialCoupon));
        } else {
            dispatch(paymentActions.setSpecialCoupon(''));
        }
        navigate('/checkout');
    };

    useEffect(() => {
        const checkFirstUserCouponAvailability = async () => {
            const coupon = 'Jw32KCIX';
            const response = await checkCouponAvailability(idToken.email, coupon);
            if (response) {
                setHasFirstUserCoupon(true);
            }
        };
        checkFirstUserCouponAvailability();
    }, []);

    return (
        <Container
            maxWidth="md"
            sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
                Recharge Storm Dollars
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            The storm dollars can be used in our software tools and is valid for 1 year since date of purchase. Select an amount or enter a custom
            amount below:
            </Typography>
            {hasFirstUserCoupon && (
                <Box sx={{ backgroundColor: 'transparent' }}>
                    <Typography variant="body1" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                        {bonusSDMessage}
                    </Typography>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => handleProceedToCheckout(160, 'Jw32KCIX')}
                    >
                        <span style={{ fontWeight: 'bold' }}>Redeem Now!</span>
                    </Button>
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { sm: 'row', xs: 'column' },
                    mt: '30px',
                }}
            >
                <Container>
                    <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                        Choose an amount
                    </Typography>
                    <ToggleButtonGroup
                        value={selectedAmount}
                        exclusive
                        onChange={handleAmountChange}
                        sx={{ marginBottom: '1rem' }}
                        color="success"
                    >
                        <ToggleButton size="large" value={70}>
                            $70
                        </ToggleButton>
                        <ToggleButton size="large" value={150}>
                            $150
                        </ToggleButton>
                        <ToggleButton size="large" value={300}>
                            $300
                        </ToggleButton>
                        <ToggleButton size="large" value={500}>
                            $500
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Container>
                <Divider orientation="vertical" variant="middle" flexItem>
                    Or
                </Divider>
                <Container>
                    <Typography variant="body1" sx={{ mb: '1rem' }}>
                        Enter your own amount
                    </Typography>
                    <TextField
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Custom Amount"
                        value={customAmount ?? ''}
                        onChange={handleCustomAmountChange}
                        sx={{ marginBottom: '1rem' }}
                    />
                </Container>
            </Box>
            <Container
                sx={{
                    marginTop: '20px',
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    rowGap: '10px',
                    justifyItems: 'end',
                    pr: { lg: '100px', md: '100px', sm: '50px', xs: '100px' },
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleProceedToCheckout(customAmount ?? selectedAmount ?? 0, '')}
                    disabled={customAmount === null && selectedAmount === null}
                    sx={{ mt: '10px' }}
                >
                    Proceed to Checkout
                </Button>
            </Container>
        </Container>
    );
}
