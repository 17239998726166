import { checkSDCredit, updateSDCredit } from './dbquery/dbquery';

export const openInNewTab = (url: any) => {
    window.open(url, '_blank')?.focus();
};

export const htmlTemplate = (modelID: string, domain: string) => {
    return `        
    <html>
      <head>
        <meta http-equiv="refresh" content="0; url='${domain}?model_id=${modelID}'"></meta>
      </head>
    </html>`;
};

export const download = (url: string, filename: string) => {
    const atag = document.createElement('a');
    atag.href = url;
    atag.download = filename;
    atag.click();
};

export const getModelId = (url: string) => {
    const urlParams = new URLSearchParams(url);
    return urlParams.get('model_id');
};

export const getRedirect = (url: string) => {
    const urlParams = new URLSearchParams(url);
    return urlParams.get('redirect');
};

export const getAppendixToken = (url: string) => {
    const urlParams = new URLSearchParams(url);
    return urlParams.get('appendixToken');
};

export function shuffleArray(array: any) {
    // Copy the original array to avoid modifying the original
    const shuffledArray = [...array];

    // Fisher-Yates (aka Knuth) Shuffle algorithm
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
}

export const toolUrls: { [key: string]: string } = {
    jco: 'https://jco-test.stormupdated.com.au/',
    su: 'http://calculator.stormupdated.com.au/wsud',
    osd: 'http://calculator.stormupdated.com.au/osds4vips',
};

export const openNewProject = (accessTokenJWT: any, model_id: string, tool: string) => {
    openInNewTab(`${toolUrls[tool]}?model_id=${model_id}&&token=${accessTokenJWT}`);
};

export const handleOpenProject = (
    clickedMenu: string,
    project: any,
    accessTokenJWT: any,
    name?: string,
) => {
    const model_id = name
        ? project[clickedMenu].models.find((model: any) => model.saveName === name).model_id
        : project[clickedMenu].models[0].model_id;

    openNewProject(accessTokenJWT, model_id, clickedMenu);
};

export const importAll = (r: any) => {
    const images: any = {};
    r.keys().forEach((item: any) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

export const checkPaymentStatus = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    let data;
    try {
        const response = await fetch(
            `https://toglm12jl3.execute-api.ap-southeast-2.amazonaws.com/test`,
            {
                method: 'POST',
                body: JSON.stringify({ sessionId: sessionId }),
            },
        );
        data = await response.json();
    } catch (error) {
        console.error('An error occurred while fetching payment status:', error);
        data = false;
    }
    return data;
};

export const handlePayment = async (
    user_id: string,
    email: string,
    isSubscribedUser: boolean,
    cost: number,
    fn: () => void,
    redirectFn: any,
) => {
    if (isSubscribedUser) {
        fn();
    } else {
        try {
            const canProceed = await handelUseSDCredit(user_id, email, -cost);

            if (canProceed) {
                fn();
            } else {
                redirectFn();
            }
        } catch (error) {
            console.error('Error using SD credit:', error);
        }
    }
};

export const handelUseSDCredit = async (user_id: string, email: string, cost: number) => {
    const sdCreditStatus = await checkSDCredit(user_id, email);
    if (sdCreditStatus.length > 0 && sdCreditStatus[0].credit_amount >= Math.abs(cost)) {
        await updateSDCredit(sdCreditStatus[0].user_id, cost);
        return true;
    } else {
        return false;
    }
};
