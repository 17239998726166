import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(
  "pk_live_51OpLi0CV2KLb3XCkPUYbcY8qP1qdOx4onMEuR7rEHLgfVXEPzaDWMlVtrF315U2JzeXJ4WpQ5e1QZNsywSFM3Piz00O8pv82qe"
);
const CheckoutFormWithoutSignIn = () => {
  const [clientSecret, setClientSecret] = useState("");
  const trainingSessionData = useSelector((state: any) => state.payment.trainingSessionData);

  const payload ={
    trainingSession: trainingSessionData.sessionName,
    email: trainingSessionData.email,
    host: window.location.host,
    token: trainingSessionData.token,
  } 
  useEffect(() => {
    fetch("https://toglm12jl3.execute-api.ap-southeast-2.amazonaws.com/test", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <Container sx={{ mt: 3 }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Container>
      )}
    </div>
  );
};

export default CheckoutFormWithoutSignIn;
