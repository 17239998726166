import { Coordinate } from './MapInterface';
import { Address } from './MapInterface';

export const incompleteStates = ['SA', 'NSW', 'QLD'];

export function inside(point: Coordinate, vs: number[][]) {
    const { lat: y, lng: x } = point;
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0];
        const yi = vs[i][1];
        const xj = vs[j][0];
        const yj = vs[j][1];

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    return inside;
}

export const CoodToGoogleLatLng = (coordinate: Coordinate) => {
    return new google.maps.LatLng(coordinate.lat, coordinate.lng);
};

export const getFeatureFromGeojson = async (
    address: Address,
    jsonData: { [key: string]: any },
): Promise<{ stationName: string; councilName: string; services_available: string[] }> => {
    for (let i = 0; i < jsonData?.features?.length; i++) {
        const feature = jsonData.features[i];
        const ps = feature.geometry.coordinates[0];
        if (inside(address.coordinate!, ps)) {
            return feature.properties;
        }
    }
    return { stationName: '', councilName: '', services_available: [] };
};

export function isInsidePolygon(point: Coordinate, polygon: google.maps.LatLng[]): boolean {
    const { lat: y, lng: x } = point;
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lng();
        const yi = polygon[i].lat();
        const xj = polygon[j].lng();
        const yj = polygon[j].lat();

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }

    return inside;
}
