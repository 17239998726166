'use client';

import React, { ChangeEvent, useEffect, useState } from 'react';
import '../../asset/css/training.css';

import {
    Button,
    Divider,
    Select,
    List,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    MenuItem,
    SelectChangeEvent,
    Grid,
    ListItem,
    ListItemText,
} from '@mui/material';

// import { getInvoicePdfBlob } from '@/utils/invoiceGeneration';
import { TrainingConfirmation } from './TrainingConfirmation';
import { checkPaymentStatus, openInNewTab } from '../../helper';

import Logo from '../../asset/images/CSW Logo Training.png';
import MirceaTraining from '../../asset/images/Mircea Training.jpg';
import { useDispatch } from 'react-redux';
import { paymentActions } from '../../redux/paymentSlice';
import { useNavigate } from 'react-router-dom';
import { recordTrainingRegistraionDetail } from '../../dbquery/dbquery';

const sessions: string[] = ['12 Nov 2024 - 13 Nov 2024'];

const courseDetails = [
    'Cost: AU$1690 + GST per trainee ',
    'Date: 12 Nov 2024 - 13 Nov 2024',
    'Time (AEST): 9AM – 4PM (includes 1 hr lunch break 12pm) ',
    'CPD Points: 16hrs ',
];

const MS = [
    'Leader in Develpoment of Stormwater Quality Software',
    'Water Engineering Consultant (10+ years across multiple civil engineering consultancies such as Hyder, BMD, Calibre).',
    'Consulting engineer & WSUD Ambassador for SQID Manufacturer (2 years).',
    'WSUD Trainer and Modelling (4 years at eWater).',
    'Various approval/engineering roles for Authorities (Barwon Water, Southern Rural Water).',
];

const insights = [
    'Understanding of the science and basic principles of WSUD.',
    'Ability to apply basic WSUD principles to support stormwater design and management.',
    'Ability to integrate WSUD treatment measures into stormwater drainage.',
    'Understanding of inputs required for WSUD modelling (rainfall data, catchments, treatments etc.).',
    'Understanding of outputs and analysis that can be done with WSUD modelling.',
    'Ability to create fully compliant WSUD models.',
    'Ability to quickly optimize WSUD models for faster turnaround.',
    'Ability to quickly generate comprehensive WSUD reports (10 x faster than business as usual) to address any project requirements.',
];

interface FormData {
    name: string;
    email: string;
    session: string;
    phoneNumber: string;
}

const TrainingPage = () => {
    const [showStripeContainer, setshowStripeContainer] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        session: sessions[0],
        phoneNumber: '',
    });

    const [errors, setErrors] = useState<{ [key in keyof FormData]: boolean }>({
        name: false,
        email: false,
        session: false,
        phoneNumber: false,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        if (sessionId) {
            const token = urlParams.get('token');
            const details = atob(token || '');
            const tokenData = JSON.parse(details);
            checkUserType(tokenData);
            setShowConfirmation(true);
        }

        async function checkUserType(tokenData: any) {
            const data = await checkPaymentStatus();
            await recordTrainingRegistraionDetail(
                data.payment_intent,
                data.name,
                data.customer_email,
                tokenData.phoneNumber,
                (+data.amount_total / 100).toString(),
                tokenData.sessionDate,
            );
        }
    }, []);
    const handleChange = (
        e:
            | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
            | SelectChangeEvent<string>,
    ) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let formIsValid = true;
        const newErrors: { [key in keyof FormData]: boolean } = { ...errors };

        // Regular expression for validating email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        Object.keys(formData).forEach((key) => {
            if (key !== 'phoneNumber') {
                if (!formData[key as keyof FormData]) {
                    newErrors[key as keyof FormData] = true;
                    formIsValid = false;
                } else {
                    // Check if the email field is valid if it's the email field
                    if (key === 'email' && !emailRegex.test(formData[key as keyof FormData])) {
                        newErrors[key as keyof FormData] = true;
                        formIsValid = false;
                    } else {
                        newErrors[key as keyof FormData] = false;
                    }
                }
            }
        });

        setErrors(newErrors);

        if (formIsValid) {
            handlePayment();
        }
    };

    const handlePayment = () => {
        dispatch(
            paymentActions.setTrainingSessionData({
                name: formData.name,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                sessionDate: formData.session,
                price: '750',
                sessionName: 'WSUD 6 hrs Training',
                token: btoa(
                    JSON.stringify({
                        sessionDate: formData.session,
                        phoneNumber: formData.phoneNumber,
                    }),
                ),
            }),
        );
        navigate('/trainingCheckout');
    };
    return (
        <>
            <div className="maintenance-page">
                <div className="registration-content">
                    {showConfirmation ? (
                        <TrainingConfirmation />
                    ) : (
                        <div>
                            <div className="headerImage">
                                <img src={Logo} alt="csw-logo" style={{ width: '40%' }} />
                            </div>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={8} md={6}>
                                    <Typography variant="h5" align="center">
                                        WSUD Concepts and Modelling Online Training
                                    </Typography>
                                </Grid>
                            </Grid>
                            <form onSubmit={handleSubmit} className="form">
                                <Grid container justifyContent="left" spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <TextField
                                            label="Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            error={errors.name}
                                            helperText={errors.name ? 'Name is required' : ''}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            error={errors.email}
                                            helperText={
                                                errors.email
                                                    ? 'Please enter a valid email addresss'
                                                    : ''
                                            }
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <TextField
                                            label="Phone Number"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Session</InputLabel>
                                            <Select
                                                name="session"
                                                label="session"
                                                value={formData.session}
                                                onChange={handleChange}
                                                error={errors.session}
                                                fullWidth
                                            >
                                                {sessions.map((session, index) => (
                                                    <MenuItem key={index} value={session}>
                                                        {session}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        className="formButtonsSection"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ flex: 1 }}
                                        >
                                            Register
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                openInNewTab(
                                                    'https://drive.google.com/file/d/1aftVZ-davYrrbiWEV7pTdIaV5Zi3T2gD/view?usp=sharing',
                                                )
                                            }
                                            color="secondary"
                                            style={{ flex: 1 }}
                                        >
                                            Download Training Course Outline
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>

                            <Grid className="subtitle">
                                <Grid item xs={12} sm={8} md={6}>
                                    <Typography>
                                        <strong>* Early bird price of $1590 + GST </strong>per
                                        participant (Starting 21 October, standard price of $1690 +GST
                                        per participant applies)
                                    </Typography>
                                    <Typography>
                                        <strong>*</strong> Registered participants will get{' '}
                                        <strong> 800 Storm Dollars</strong> in our tools (valid upto
                                        1 year).
                                    </Typography>
                                    <Typography>
                                        <strong>*</strong> In-person/personalized training also
                                        available, please contact us.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                                container
                                justifyContent="space-between"
                                direction="row"
                                textAlign={'left'}
                                spacing={'2vw'}
                                style={{ padding: '1vh 1vw' }}
                            >
                                <Grid item xs={12} sm={8} md={6}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="h5" align="left">
                                            Course Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <List dense>
                                            {courseDetails.map((item, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText primary={item} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} spacing={0}>
                                        <Typography variant="body2">
                                            Facilitated by Mircea Stancu, RPEng- Civil:
                                        </Typography>

                                        <List dense>
                                            {MS.map((item, index) => (
                                                <ListItem
                                                    key={index}
                                                    disablePadding
                                                    sx={{ marginLeft: '1vw' }}
                                                >
                                                    <ListItemText primary={'- ' + item} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="body2">
                                            Avoid spending hours sifting through the new guidelines.
                                            Our training session focuses on practical modelling and
                                            reporting aspects needed to get a head start in
                                            submissions to authorities, including case studies AND{' '}
                                            <br />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="body2">Insights on:</Typography>
                                        <List dense>
                                            {insights.map((item, index) => (
                                                <ListItem
                                                    key={index}
                                                    disablePadding
                                                    sx={{ marginLeft: '1vw' }}
                                                >
                                                    <ListItemText primary={'- ' + item} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={6}
                                    justifyContent="center"
                                    textAlign={'center'}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className="guidelines"
                                        spacing={0}
                                    >
                                        <img
                                            src={MirceaTraining}
                                            alt="csw-logo"
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Typography textAlign={'left'} marginTop={'2vh'}>
                                Contact: mircea@cleanstormwater.com.au (0433 030 044)
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TrainingPage;
