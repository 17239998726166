import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  amountGet: number;
  amountToPay: number;
  subscriptionList: string[];
  paymentType: string;
  discountApplied: number;
  subscriptionDuration: string;
  couponUsed: { service_name: string; code: string; action: string; amount: number };
  specialCoupon: string;
  trainingSessionData: {
    sessionName: string;
    name: string;
    email: string;
    sessionDate: string;
    phoneNumber: string;
    price: string;
    token: string;
  }
}

const initialState = {
  amountGet: 0,
  amountToPay: 0,
  subscriptionList: [],
  paymentType: "",
  discountApplied: 0,
  subscriptionDuration: "7",
  couponUsed: { service_name: "", code: "", action: "", amount: 0 },
  specialCoupon: "",
  trainingSessionData: {
    sessionName: '',
    name: '',
    email: '',
    sessionDate: '',
    phoneNumber: '',
    price: '',
    token: '',
  }};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setAmountGet: (state: State, action: PayloadAction<any>) => {
      state.amountGet = action.payload;
    },
    setAmountToPay: (state: State, action: PayloadAction<any>) => {
      state.amountToPay = action.payload;
    },
    setSubscriptionList: (state: State, action: PayloadAction<any>) => {
      state.subscriptionList = action.payload;
    },
    setPaymentType: (state: State, action: PayloadAction<any>) => {
      state.paymentType = action.payload;
    },
    setDiscountApplied: (state: State, action: PayloadAction<any>) => {
      state.discountApplied = action.payload;
    },
    setSubscriptionDuration: (state: State, action: PayloadAction<any>) => {
      state.subscriptionDuration = action.payload;
    },
    setCouponUsed: (state: State, action: PayloadAction<any>) => {
      state.couponUsed = action.payload;
    },
    setSpecialCoupon: (state: State, action: PayloadAction<any>) => {
      state.specialCoupon = action.payload;
    },
    setTrainingSessionData: (state: State, action: PayloadAction<any>) => {
      state.trainingSessionData = action.payload; 
    }
  },
});

export const { actions: paymentActions, reducer: paymentReducer } =
  paymentSlice;
export default paymentSlice.reducer;
