import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  addCompanySubAccount,
  deleteCompanySubAccount,
  getCompanySubAccount,
} from "../../dbquery/dbquery";

const ManageCompanyAccount = (props: { companyId: number }) => {
  const [emails, setEmails] = useState("");
  const [subAccounts, setSubAccounts] = useState<any>([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const fetchCompanySubAccounts = async () => {
    const subAccounts = await getCompanySubAccount(props.companyId);
    setSubAccounts(subAccounts.map((subAccount: any) => subAccount.email));
  };
  useEffect(() => {
   
    fetchCompanySubAccounts();
  }, [props.companyId]);

  const handleEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmails(event.target.value);
  };

  const handleAddSubAccounts = async () => {
    const newEmails = emails.split(",").map((email) => email.trim());
    await addCompanySubAccount(props.companyId, newEmails);
    setEmails("");
    fetchCompanySubAccounts();
  };

  const handleDelete = (index: number) => {
    setDeleteIndex(index);
  };

  const handleConfirmDelete = async () => {
    await deleteCompanySubAccount(
      props.companyId,
      subAccounts[deleteIndex!]
    );
    setDeleteIndex(null);
    fetchCompanySubAccounts();
  };

  const handleCloseDialog = () => {
    setDeleteIndex(null);
  };

  return (
    <Container>
      <Typography variant="h5" align="center" gutterBottom mt={5}>
        Company Account Management
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        Add or remove sub-accounts for your company. Sub-accounts share access
        to your company's Storm Dollars and tool subscriptions.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ margin: "auto", padding: 2 }}>
            <TextField
              label="Email Addresses"
              variant="outlined"
              value={emails}
              onChange={handleEmailsChange}
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" sx={{ margin: "10px 5px 15px" }}>
              * You can input multiple email addresses separated by commas
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSubAccounts}
            >
              Add Sub-Accounts
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ margin: "auto", padding: 2 }}>
            <List sx={{ marginTop: 2 }}>
              {subAccounts.map((subAccount: any, index: number) => (
                <ListItem key={index}>
                  <ListItemText primary={subAccount} />
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </Button>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={deleteIndex !== null} onClose={handleCloseDialog}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this email address?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ManageCompanyAccount;
