import axios from 'axios';

export const LAMBDA_API = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com';
const S3 = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/s3';
const MARKETING = 'https://dv51u50z3m.execute-api.ap-southeast-2.amazonaws.com/stage';
const JCO_BACKEND_LAMBDA_API =
    'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/jco-backend';
const NEW_USER_PORTAL_RELATED = 'https://4bjkati12a.execute-api.ap-southeast-2.amazonaws.com/stage';
const SAVE_AND_LOAD_MODEL = 'https://y7dtd8y3bj.execute-api.ap-southeast-2.amazonaws.com/stage';
const FEATURESTOOL = LAMBDA_API + '/marketing';
const GET_METHODS = LAMBDA_API + '/get-methods';
const USER_LOGS_API = 'https://hsrj73fhkj.execute-api.ap-southeast-2.amazonaws.com';

export interface BackendPayload {
    endpoint: string;
    params: any;
}
const dbquery = async (query: BackendPayload, API: string) => {
    return (await axios.post(API, query)).data.results;
};

const lambdaQuery = async (query: BackendPayload, API: string) => {
    return (await axios.post(API, query)).data;
};

export const addSubscriptionList = async (user_id: string, tool_name: string, duration: number) => {
    const payload = {
        endpoint: 'addSubscriptionList',
        params: {
            user_id,
            tool_name,
            duration,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const updateSDCredit = async (user_id: string, credit_amount: number) => {
    const payload = {
        endpoint: 'updateSDCredit',
        params: {
            user_id,
            credit_amount,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const addPaymentRecord = async (
    user_id: string,
    purchase_type: string,
    amount_required: string,
    amount_paid: string,
) => {
    const payload = {
        endpoint: 'addPaymentRecord',
        params: {
            user_id,
            purchase_type,
            amount_required,
            amount_paid,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getAllSubscriptionStatus = async (user_id: string) => {
    const payload = {
        endpoint: 'getAllSubscriptionStatus',
        params: {
            user_id,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const checkSDCredit = async (user_id: string, email?: string) => {
    const payload = {
        endpoint: 'checkSDCredit',
        params: {
            user_id,
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getModelsForUser = async (assessor_email: string) => {
    const payload = {
        endpoint: 'getModelsForUser',
        params: {
            assessor_email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getMostRecentModel = async (email: string) => {
    const payload = {
        endpoint: 'getMostRecentModel',
        params: {
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getJcoReportsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getJcoReportsForUser',
        params: {
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getSuReportsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getSuReportsForUser',
        params: {
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getOsdReportsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getOsdReportsForUser',
        params: {
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getFileFromS3 = async (bucket: string, filename: string) => {
    const params = { bucket, filename };
    const formData = new FormData();

    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'getFileFromS3');

    try {
        const response = (await axios.post(S3, formData)).data;
        return response;
    } catch (err) {
        console.log(err);
    }
    return '';
};

// const API = 'http://localhost:5000/s3'
export const uploadToS3 = async (
    fileContent: Blob,
    fileName: string,
    bucket = 'jco-background-images',
) => {
    const params = { bucket, key: `${fileName}` };
    const formData = new FormData();

    formData.append('image', fileContent, fileName);
    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'uploadToS3');

    return await axios.post(S3, formData);
};

export const getImageUrlFromS3 = async (bucket: string, filename: string) => {
    const params = { bucket, filename };
    const formData = new FormData();

    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'getFileFromS3');

    try {
        const response = (await axios.post(S3, formData)).data.results;
        return response;
    } catch (err) {
        console.log(err);
    }
    return '';
};

export const getUserGuideNewFeatures = async () => {
    const payload = {
        endpoint: 'getNewFeatures',
        params: '',
    };
    return await lambdaQuery(payload, MARKETING);
};

export const getMethodsAPI = (params: object) => {
    return axios.get(GET_METHODS, { params });
};

export const getPopUpAd = async (tool: string) => {
    const payload = {
        endpoint: 'getPopUpAdvertisementByTool',
        tool,
    };
    return await getMethodsAPI(payload);
};

export const checkCouponAvailability = async (email: string, coupon_code: string) => {
    const payload = {
        endpoint: 'checkCouponAvailability',
        params: {
            email,
            coupon_code,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const updateCouponUsedHistory = async (email: string, coupon_code: string) => {
    const payload = {
        endpoint: 'updateCouponUsedHistory',
        params: {
            email,
            coupon_code,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const rechargeSDCredit = async (
    user_id: string,
    transaction_id: string,
    amount_required: number,
    amount_paid: number,
) => {
    const payload = {
        endpoint: 'rechargeSDCredit',
        params: {
            user_id,
            transaction_id,
            amount_required,
            amount_paid,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const handleSubscriptionPaymentSuccess = async (
    user_id: string,
    subscription_id: string,
    subscription_name: string,
    amount_required: number,
    amount_paid: number,
    duration: number,
) => {
    const payload = {
        endpoint: 'handleSubscriptionPaymentSuccess',
        params: {
            user_id,
            subscription_id,
            subscription_name,
            amount_required,
            amount_paid,
            duration,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const checkIsCompanyAdminAccount = async (email: string) => {
    const payload = {
        endpoint: 'checkIsCompanyAdminAccount',
        params: {
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getCompanySubAccount = async (company_id: number) => {
    const payload = {
        endpoint: 'getCompanySubAccount',
        params: {
            company_id,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const addCompanySubAccount = async (company_id: number, email: string[]) => {
    const payload = {
        endpoint: 'addCompanySubAccount',
        params: {
            company_id,
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const deleteCompanySubAccount = async (company_id: number, email: string) => {
    const payload = {
        endpoint: 'deleteCompanySubAccount',
        params: {
            company_id,
            email,
        },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const countClick = async (email: string, button_id: number) => {
    const payload = {
        endpoint: 'countClick',
        params: { email, button_id },
    };
    return await lambdaQuery(payload, MARKETING);
};

export const getAllAdvertisement = async () => {
    const payload = { endpoint: 'getAllAdvertisement', params: {} };
    return await dbquery(payload, JCO_BACKEND_LAMBDA_API);
};

export const getAllCouncilLinks = async () => {
    const payload = { endpoint: 'getAllCouncilLinks', params: {} };
    return await dbquery(payload, JCO_BACKEND_LAMBDA_API);
};

export const getCouncilRulesForOneMlb = async (mlb_name: string) => {
    const payload = {
        endpoint: 'getCouncilRulesForOneMlb',
        params: { mlb_name },
    };
    return await dbquery(payload, JCO_BACKEND_LAMBDA_API);
};

export const checkIsCompanySubAccount = async (email: string) => {
    const payload = { endpoint: 'checkIsCompanySubAccount', params: { email } };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const saveProjectDetails = async (
    user_email: string,
    address: string,
    suburb: string,
    state: string,
    postcode: string,
    city_council: string,
    rainfall_station: string,
    lat: string,
    lng: string,
) => {
    const payload = {
        endpoint: 'saveProjectDetails',
        params: {
            user_email,
            address,
            suburb,
            state,
            postcode,
            city_council,
            rainfall_station,
            lat,
            lng,
        },
    };
    return await lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

export const saveOrGetProjectDetails = async (
    user_email: string,
    address: string,
    suburb: string,
    state: string,
    postcode: string,
    city_council: string,
    rainfall_station: string,
    lat: string,
    lng: string,
) => {
    const payload = {
        endpoint: 'saveOrGetProjectDetails',
        params: {
            user_email,
            address,
            suburb,
            state,
            postcode,
            city_council,
            rainfall_station,
            lat,
            lng,
        },
    };
    return await lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

export const recordTrainingRegistraionDetail = async (
    invoice_id: string,
    name: string,
    email: string,
    phone: string,
    price_paid: string,
    session_date: string,
) => {
    const payload = {
        endpoint: 'recordTrainingRegistraionDetail',
        params: { invoice_id, name, email, phone, price_paid, session_date },
    };
    return await dbquery(payload, FEATURESTOOL);
};

export const getUserNameAndId = async (email: string, password: string) => {
    const payload = {
        endpoint: 'getUserNameAndId',
        params: { email, password },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getJcoModelsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getJcoModelsForUser',
        params: { email },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getSuModelsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getSuModelsForUser',
        params: { email },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getOsdModelsForUser = async (email: string) => {
    const payload = {
        endpoint: 'getOsdModelsForUser',
        params: { email },
    };
    return await lambdaQuery(payload, NEW_USER_PORTAL_RELATED);
};

export const getTotoevAssets = async (usage: string) => {
    // Public API
    const baseUrl = 'https://thcm64b8cb.execute-api.ap-southeast-2.amazonaws.com';
    const data = await axios.get(`${baseUrl}/totoev-assets?usage=${usage}`);
    return data.data;
};

export const checkIfCouncilInSU = async (rainfallStation: string) => {
    const payload = {
        endpoint: 'isCouncilInSU',
        params: { rainfallStation },
    };
    return dbquery(payload, `${LAMBDA_API}/cms`);
};

export const recordUserLogs = async (project_id: string, tool: string, event: string) => {
    const payload = {
        endpoint: 'add_user_log',
        project_id,
        tool,
        event,
    };

    try {
        return fetch(`${USER_LOGS_API}/record`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    } catch (error) {
        console.log(error);
    }
};
