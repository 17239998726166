// Dashboard.js
import { useJsApiLoader } from '@react-google-maps/api';
import { Container, Grid, Paper } from '@mui/material';
import RecentProjectsSection from './RecentProjects';
import FeaturesTool from '../featuresTool/FeaturesContainer';
import GoogleMap from '../googleMap/GoogleMap';
const libraries = ['places'];

const Dashboard = () => {
    const { isLoaded } = useJsApiLoader({
        // googleMapsApiKey: 'AIzaSyBXWqbpWjUJpXbknlMwWDhwN0IEBzRWxCE',
        googleMapsApiKey: 'AIzaSyCmrEUjIbVmczEXz5Lrw7MOZsmKv8xziCQ',
        libraries: libraries as any,
    });
    return (
        <Container maxWidth="lg" style={{ marginTop: 20 }}>
            <Grid container spacing={3}>
                {/* Create New Project Section */}
                {/* <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <CreateNewProjectSection />
          </Paper>
        </Grid> */}

                {/* Recent Projects Section */}
                {isLoaded && (
                    <Grid item xs={12} md={12}>
                        <div style={{ padding: 5 }}>
                            <h3>Enter a Location to Start Project</h3>
                        </div>
                        <Paper elevation={3} style={{ padding: 20 }}>
                            <GoogleMap />
                        </Paper>
                    </Grid>
                )}

                {/* Recent News/Updates Section */}
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} style={{ padding: 20 }}>
                        <FeaturesTool />
                    </Paper>
                </Grid>

                {/* Current Balance Section */}
                <Grid item xs={6} md={4}>
                    <Paper elevation={3} style={{ padding: 20 }}>
                        <RecentProjectsSection />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
