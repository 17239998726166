import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar';
import { sidebarActions } from '../../redux/sidebarSlice';
import './styles.css';
import '@aws-amplify/ui-react/styles.css';
//import icons
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { IoWalletOutline, IoPricetagsOutline } from 'react-icons/io5';
import { TbReportAnalytics, TbTools } from 'react-icons/tb';
import { FaDiagramProject } from 'react-icons/fa6';
import { MdAttachMoney } from 'react-icons/md';
import { RiBillLine, RiHome4Line } from 'react-icons/ri';
import jcoLogo from '../../asset/images/jco-logo.png';
import suLogo from '../../asset/images/Su Calc Logo Square.png';
import osdLogo from '../../asset/images/OSD4VIPS_Logo.png';
import btbLogo from '../../asset/images/BTB Logo.png';
import tankSizeLogo from '../../asset/images/Tank Size Logo2.png';
import auditorLogo from '../../asset/images/Auditor Logo.png';
import { openInNewTab } from '../../helper';

function WebSidebar() {
    const dispatch = useDispatch();
    const isSidebarCollapsed = useSelector((state: any) => state.sidebar.collapsed);
    const isSidebarMinimized = useSelector((state: any) => state.sidebar.minimized);
    const isSidebarMinimizedOpen = useSelector((state: any) => state.sidebar.minimizedOpen);
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);
    const handleCollapseSidebar = () => {
        dispatch(sidebarActions.toggleCollapse());
    };
    const handleMinimizeSidebar = () => {
        dispatch(sidebarActions.toggleMinimize());
    };

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (700 < windowWidth && windowWidth < 1200) {
            if (!isSidebarCollapsed) {
                handleCollapseSidebar();
            }
            if (isSidebarMinimized) {
                handleMinimizeSidebar();
            }
        }
        if (windowWidth < 700 && !isSidebarMinimized) {
            handleMinimizeSidebar();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isSidebarCollapsed, isSidebarMinimized]);

    return (
        <div>
            <Sidebar
                style={{ position: 'absolute' }}
                collapsed={isSidebarCollapsed || isSidebarMinimized}
                collapsedWidth="70px"
            >
                <main>
                    <Menu>
                        {!isSidebarCollapsed ? (
                            <MenuItem
                                suffix={<FiChevronsLeft />}
                                onClick={handleCollapseSidebar}
                                className="sidebar-submenu"
                            >
                                <div
                                    style={{
                                        padding: '9px',
                                        fontSize: 16,
                                        letterSpacing: '1px',
                                    }}
                                >
                                    Hi {idToken['name'] || 'User'}
                                </div>
                            </MenuItem>
                        ) : !isSidebarMinimized ? (
                            <MenuItem
                                icon={<FiChevronsRight />}
                                onClick={handleCollapseSidebar}
                            ></MenuItem>
                        ) : null}
                    </Menu>
                    {isSidebarMinimizedOpen || !isSidebarMinimized ? (
                        <Menu>
                            <MenuItem
                                className="sidebar-submenu"
                                icon={<RiHome4Line />}
                                component={<Link to="/dashboard" />}
                            >
                                New Project
                            </MenuItem>

                            <SubMenu
                                defaultOpen
                                label={'Manage Projects'}
                                icon={<TbReportAnalytics />}
                                className="sidebar-submenu"
                            >
                                <MenuItem
                                    icon={<FaDiagramProject />}
                                    className="sidebar-menu-item"
                                    component={<Link to="/project-history" />}
                                >
                                    Saved Projects
                                </MenuItem>
                                <MenuItem
                                    icon={<RiBillLine />}
                                    className="sidebar-menu-item"
                                    disabled
                                >
                                    Cost Details
                                </MenuItem>
                            </SubMenu>

                            <SubMenu
                                defaultOpen
                                label={'Credits'}
                                icon={<AiOutlineCreditCard />}
                                className="sidebar-submenu"
                            >
                                <MenuItem
                                    icon={<IoWalletOutline />}
                                    className="sidebar-menu-item"
                                    component={<Link to="/purchase-credits" />}
                                >
                                    Recharge Storm Dollars
                                </MenuItem>
                                <MenuItem
                                    icon={<IoPricetagsOutline />}
                                    className="sidebar-menu-item"
                                    component={<Link to="/subscription" />}
                                >
                                    Subscribe a Service
                                </MenuItem>
                                <MenuItem
                                    icon={<MdAttachMoney />}
                                    className="sidebar-menu-item"
                                    disabled
                                >
                                    Check payment options
                                </MenuItem>
                            </SubMenu>

                            <SubMenu
                                defaultOpen
                                label={'Tool Guides'}
                                icon={<TbTools />}
                                className="sidebar-submenu"
                            >
                                <MenuItem
                                    icon={<img src={jcoLogo} style={{ width: '14px' }} alt="JCO" />}
                                    className="sidebar-menu-item"
                                    onClick={() =>
                                        openInNewTab(
                                            `https://www.stormupdated.com.au/help/tool-feature-table/jco`,
                                        )
                                    }
                                >
                                    John Connor Online
                                </MenuItem>
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={jcoLogo}
                                                style={{ width: '14px' }}
                                                alt="OSD"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/?token=${accessTokenJWT}
                                                &&addressToken=eyJzdGF0ZSI6IlZJQyIsImNvdW5jaWwiOiJDaXR5IG9mI
                                                E1lbGJvdXJuZSIsImFkZHJlc3MiOiJzdWl0ZSAxLCBsZXZlbCAxMCwgTWVsY
                                                m91cm5lIFZJQyAzMDAwLCBBdXN0cmFsaWEiLCJyYWluZmFsbFN0YXRpb24iOi
                                                JNZWxib3VybmUgQ2l0eSIsInN1YnVyYiI6Ik1lbGJvdXJuZSIsInBvc3Rjb2Rl
                                                IjoiMzAwMCIsImNvb3JkaW5hdGUiOnsibGF0IjotMzcuODEzMzcyLCJsbmciOj
                                                E0NC45Njg4NDg5fX0=`,
                                            )
                                        }
                                    >
                                        JCO (dev)
                                    </MenuItem>
                                )}
                                <MenuItem
                                    icon={<img src={suLogo} style={{ width: '14px' }} alt="SU" />}
                                    className="sidebar-menu-item"
                                    onClick={() =>
                                        openInNewTab(
                                            `https://www.stormupdated.com.au/help/tool-feature-table/su`,
                                        )
                                    }
                                >
                                    SU Calculator
                                </MenuItem>
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img src={suLogo} style={{ width: '14px' }} alt="SU" />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/wsud?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        SU Calculator (dev)
                                    </MenuItem>
                                )}
                                <MenuItem
                                    icon={<img src={osdLogo} style={{ width: '14px' }} alt="OSD" />}
                                    className="sidebar-menu-item"
                                    onClick={() =>
                                        openInNewTab(
                                            `https://www.stormupdated.com.au/help/tool-feature-table/osd`,
                                        )
                                    }
                                >
                                    OSDs for VIPs
                                </MenuItem>
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={osdLogo}
                                                style={{ width: '14px' }}
                                                alt="OSD"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/osds4vips?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        OSDs for VIPs (dev)
                                    </MenuItem>
                                )}
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={osdLogo}
                                                style={{ width: '14px' }}
                                                alt="OSD"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/rwt-calculator?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        RWT Calculator (dev)
                                    </MenuItem>
                                )}
                                <MenuItem
                                    icon={
                                        <img
                                            src={tankSizeLogo}
                                            style={{ width: '14px' }}
                                            alt="OSD"
                                        />
                                    }
                                    className="sidebar-menu-item"
                                    onClick={() =>
                                        openInNewTab(
                                            `https://www.stormupdated.com.au/help/tool-feature-table/su`,
                                        )
                                    }
                                >
                                    RWT Sizing
                                </MenuItem>
                                <MenuItem
                                    icon={
                                        <img
                                            src={auditorLogo}
                                            style={{ width: '14px' }}
                                            alt="Aud"
                                        />
                                    }
                                    className="sidebar-menu-item"
                                    disabled
                                >
                                    Auditor of MUSIC
                                </MenuItem>
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={auditorLogo}
                                                style={{ width: '14px' }}
                                                alt="Auditor"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/auditor?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        Auditor (dev)
                                    </MenuItem>
                                )}
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={auditorLogo}
                                                style={{ width: '14px' }}
                                                alt="Auditor"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `https://auditor-integration.d25vkxkrp2yx8t.amplifyapp.com/auditor?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        Auditor (Test)
                                    </MenuItem>
                                )}
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={auditorLogo}
                                                style={{ width: '14px' }}
                                                alt="convert"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `https://auditor-integration.d25vkxkrp2yx8t.amplifyapp.com/auditor?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        Converter
                                    </MenuItem>
                                )}

                                <MenuItem
                                    icon={<img src={btbLogo} style={{ width: '14px' }} alt="BTB" />}
                                    className="sidebar-menu-item"
                                    disabled
                                >
                                    BOM The Builder
                                </MenuItem>
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={btbLogo}
                                                style={{ width: '14px' }}
                                                alt="BTB"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://localhost:3000/?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        BOM The Builder (dev)
                                    </MenuItem>
                                )}
                                {idToken['email'].includes('cleanstormwater.com.au') && (
                                    <MenuItem
                                        icon={
                                            <img
                                                src={btbLogo}
                                                style={{ width: '14px' }}
                                                alt="BTB"
                                            />
                                        }
                                        className="sidebar-menu-item"
                                        onClick={() =>
                                            openInNewTab(
                                                `http://btb-front-end.s3-website-ap-southeast-2.amazonaws.com/?token=${accessTokenJWT}&&project=21572`,
                                            )
                                        }
                                    >
                                        BOM The Builder (Test)
                                    </MenuItem>
                                )}
                            </SubMenu>
                        </Menu>
                    ) : null}
                </main>
            </Sidebar>
        </div>
    );
}
export default WebSidebar;
