import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMostRecentModel } from '../../dbquery/dbquery';
import { download, htmlTemplate, openNewProject, toolUrls } from '../../helper';
import { Card, CardContent, Container, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';

const slotProps = {
    popper: {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -14],
                },
            },
        ],
    },
};

const RecentProjectsSection = () => {
    const [recentProject, setRecentProject] = useState<any>({});
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const project = await getMostRecentModel(idToken.email);
                const parsedProject = {
                    ...project,
                    addressString: project.address,
                    date: project.time.slice(0, 10),
                    name: project['project-name'],
                };
                setRecentProject(parsedProject);
            } catch (error) {
                console.error('Error fetching recent project:', error);
            }
        };
        fetchData();
    }, []);

    const downloadHtml = (modelId: any, projectAddress: string) => {
        const htmlFile = new Blob([htmlTemplate(modelId, toolUrls['jco'])], { type: 'text/plain' });
        const fileURL = URL.createObjectURL(htmlFile);
        download(fileURL, `${projectAddress}.html`);
    };

    return (
        <div>
            {Object.keys(recentProject).length > 0 && (
                <>
                    <h2>Continue last project</h2>
                    <Card
                        sx={{
                            mt: 2,
                            minHeight: '166px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                        elevation={4}
                    >
                        <CardContent>
                            <p>Address: {recentProject.addressString}</p>
                            <p>Last Modified Date: {recentProject.date}</p>
                        </CardContent>
                        <Container sx={{ display: 'flex' }}>
                            <Tooltip
                                title="Download HTML file"
                                placement="top"
                                slotProps={slotProps}
                            >
                                <ListItemButton>
                                    <ListItemIcon
                                        onClick={() =>
                                            downloadHtml(
                                                recentProject.model_id,
                                                recentProject.addressString,
                                            )
                                        }
                                    >
                                        <DownloadIcon sx={{ color: 'black' }} />
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                            <Tooltip
                                title="Open saved project"
                                placement="top"
                                slotProps={slotProps}
                            >
                                <ListItemButton>
                                    <ListItemIcon
                                        onClick={() =>
                                            openNewProject(
                                                accessTokenJWT,
                                                recentProject.model_id,
                                                'jco',
                                            )
                                        }
                                    >
                                        <OpenInNewIcon sx={{ color: 'black' }} />
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                            <Tooltip title="Download MSF" placement="top" slotProps={slotProps}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DescriptionIcon sx={{ color: 'black' }} />
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </Container>
                    </Card>
                </>
            )}
        </div>
    );
};

export default RecentProjectsSection;
